import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { adjustPinActions } from "../../store/adjust-pin-slice";
import { oldMonitorsActions } from "../../store/old-monitors-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/monitor-adjust-position.module.css";

export const MonitorAdjustPositionData = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const monitor = useSelector((state: RootState) => state.monitor.monitor);
  const appointment = useSelector((state: RootState) => state.appointment);
  const { monitorPosition, position, pinName, location } = useSelector(
    (state: RootState) => state.adjustPin
  );
  const monitorTypes = useSelector(
    (state: RootState) => state.monitor.monitorTypes
  );

  const [textArea, setTextArea] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const pinAbbr = {
    name: pinName,
    number: position.toString() ?? "001",
  };

  const adjustmentStep = useSelector(
    (state: RootState) => state.tabs.adjustment.step
  );

  const handleBack = () => {
    if (adjustmentStep === 1) {
      navigate(-1);
    } else {
      dispatch(activeTabsActions.setAdjustmentStep(1));
    }
  };

  const setLocation = (location: string) => {
    dispatch(adjustPinActions.setLocation(location));
  };

  const setPosition = (number: string) => {
    dispatch(adjustPinActions.setPosition(number));
  };

  const saveChanges = async () => {
    const currentTime = new Date().toISOString();

    dispatch(
      oldMonitorsActions.setOldMonitors({
        id: monitor.id,
        position: monitor.position.toString(),
        location: monitor.location,
        appointmentId: appointment.id,
        createdAt: currentTime,
        action: "adjusted",
      })
    );

    setLoading(true);

    await AppointmentService.UpdateMonitorDetails(appointment.id, {
      monitor_id: monitor.id,
      position,
      location,
      reason: textArea,
      mapPosition: {
        mapVersionId: null,
        mapPoint: {
          ...monitorPosition,
        },
      },
      is_active: true,
    })
      .then(() => {
        dispatch(adjustPinActions.resetState());
        dispatch(activeTabsActions.setAdjustmentStep(1));
        navigate(`/properties/${appointment.id}/show`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTextareaChange = (note: string) => {
    setTextArea(note);
  };

  const handleFirstContinueClick = () => {
    dispatch(activeTabsActions.setAdjustmentStep(2));
  };

  return (
    <AppLayout hideHeader>
      <BackButtonHeader handleBackButtonClicked={handleBack} />
      <AppointmentDetails />
      {adjustmentStep === 1 ? (
        <div className={styles["content-wrapper"]}>
          <div className={styles["content"]}>
            <p className={styles["monitor-adjust--title"]}>
              <MonitorIcon color="dark" /> {monitor?.monitorType} {position}
            </p>

            <div
              className={`${styles["monitor-adjust--map"]} ${styles["monitor-adjust-data--map"]}`}
            >
              <PinCropMap
                map={monitorTypes.map!}
                crop={monitorPosition}
                onCropChange={() => {}}
                monitorPinAbbr={pinAbbr}
                isDraggable={false}
                zoom={false}
              />
            </div>

            <CustomInput
              label="Standortinformation"
              placeholder="Kein Standort eingetragen"
              onInputChange={setLocation}
              onBlur={() => {}}
              initialValue={location}
              maxLength={60}
            />

            <CustomInput
              label="Monitor Nummer"
              placeholder="Kein Nummer eingetragen"
              onInputChange={setPosition}
              onBlur={() => {}}
              initialValue={position.toString()}
              maxLength={10}
              numeric={true}
            />
          </div>
          <FloatingButtons>
            <CustomButton
              color="gray"
              onClick={handleFirstContinueClick}
              width={80}
            >
              {t("Confirm")}
            </CustomButton>
          </FloatingButtons>
        </div>
      ) : (
        <div className={styles["content-wrapper"]}>
          <div className={styles["content"]}>
            <span className={styles["monitor-adjust--title"]}>
              {t("AdjustPosition")}
            </span>

            <CustomTextArea
              placeholder={t("MovingMonitorReason")}
              onTextareaChange={handleTextareaChange}
              defaultValue={textArea}
            />
          </div>
          <FloatingButtons>
            <CustomButton
              color="gray"
              width={80}
              onClick={saveChanges}
              loading={loading}
            >
              {t("Continue")}
            </CustomButton>
          </FloatingButtons>
        </div>
      )}
    </AppLayout>
  );
};
