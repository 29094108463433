import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Document } from "../types/document";
import { ImageProps } from "../types/image";
import { MonitorService } from "../types/monitor";

export type MonitorSliceState = {
  inProgress: boolean;
  propertyId: string;
  location: string;
  comment: string;
  isTemporary: boolean;
  monitorTypes: MonitorTypes;
  monitorPosition: MonitorPosition;
  monitor: MonitorService;
  images: ImageProps[];
};

export type MonitorTypes = {
  monitorType: string;
  propertyArea: string;
  propertyAreaId: string;
  monitors: MonitorService[];
  map: Document | null;
};

type MonitorPosition = {
  x: number;
  y: number;
};

const initialState: MonitorSliceState = {
  inProgress: false,
  propertyId: "",
  location: "",
  comment: "",
  isTemporary: false,
  monitorTypes: {
    monitorType: "",
    propertyArea: "",
    propertyAreaId: "",
    monitors: [],
    map: null,
  },
  monitorPosition: {
    x: 1,
    y: 1,
  },
  monitor: {
    id: "",
    isActive: true,
    monitorType: "",
    monitorTypeId: "",
    position: 0,
    location: "",
    x: 1,
    y: 1,
  },
  images: [],
};

export const creatingMonitorSlice = createSlice({
  name: "monitor",
  initialState: initialState,
  reducers: {
    setInProgress(state, action: PayloadAction<boolean>) {
      state.inProgress = action.payload;
    },
    setPropertyId(state, action: PayloadAction<string>) {
      state.propertyId = action.payload;
    },
    setLocation(state, action: PayloadAction<{ location: string }>) {
      const location = action.payload.location;
      state.location = location;
    },
    setIsTemporary(state: MonitorSliceState) {
      state.isTemporary = !state.isTemporary;
    },
    setComment(state, { payload }: PayloadAction<{ comment: string }>) {
      state.comment = payload.comment;
    },
    setMonitorTypes(state, { payload }: PayloadAction<MonitorTypes>) {
      state.monitorTypes = payload;
    },
    setMonitorPosition(state, { payload }: PayloadAction<MonitorPosition>) {
      state.monitorPosition = payload;
    },
    setMonitorInfoForServices(
      state,
      { payload }: PayloadAction<MonitorService>
    ) {
      state.monitor = payload;
    },
    addImage(state, { payload }: PayloadAction<ImageProps>) {
      state.images.push(payload);
    },
    removeImage(state, { payload }: PayloadAction<string>) {
      state.images = state.images.filter((image) => image.uuid !== payload);
    },
    resetState(state) {
      return {
        ...initialState,
        monitor: state.monitor,
        monitorTypes: state.monitorTypes,
      };
    },
  },
});

export const creatingMonitorActions = creatingMonitorSlice.actions;
