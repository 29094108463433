import { Dashboard } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import CustomerService from "../../../api/CustomerService";
import UserService from "../../../api/UserService";
import { LastServices } from "../../../components/LastServices/LastServices";
import { SubMenuButton } from "../../../components/SubMenuButton/SubMenuButton";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import { RootState } from "../../../store";
import {
  PropertyInfo,
  ROLES,
  userSliceActions,
} from "../../../store/user-slice";
import styles from "../../../styles/customer-dashboard.module.css";
import { Property } from "../../../types/property";

export type ButtonType = "PastCustomerVisits" | "UpcomingCustomerVisits";

const SUB_MENU_BUTTONS: ButtonType[] = [
  // "UpcomingCustomerVisits",  comment this tab for now
  "PastCustomerVisits",
];

const CustomerDashboard = () => {
  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] =
    useState<ButtonType>("PastCustomerVisits");
  const [loading, setLoading] = useState(true);
  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  const handleChangeTab = (tab: ButtonType) => {
    setCurrentTab(tab);
  };

  const fetchCustomerProperties = async (customerId: string) => {
    const { data } = await CustomerService.GetCustomerProperties(customerId);
    return data.map((property: Property) => ({
      id: property.id,
      street: property.street,
      postcode: property.postcode,
      city: property.city,
      isSevdeskProperty: property.isSevdeskProperty,
      customerReferenceNumber: property.customerReferenceNumber,
    }));
  };

  useEffect(() => {
    UserService.GetCurrentUser().then(({ data }) => {
      dispatch(userSliceActions.setCurrentUser(data));

      const isObjekbetreuer = data.roles?.includes(ROLES.PROPERTY_MANAGER);

      if (!data.customerId) {
        setLoading(false);
        return;
      }

      fetchCustomerProperties(data.customerId).then(
        (filteredProperties: PropertyInfo[]) => {
          let propertiesToSave = filteredProperties;

          const sevdeskProperties = filteredProperties.filter(
            (property) => property.isSevdeskProperty === 0
          );

          if (!isObjekbetreuer) {
            const dbProps = filteredProperties.filter(
              (property) => property.isSevdeskProperty === 1
            );

            // Get the referenceNumbers from properties where isSevdeskProperty is 1
            const dbPropsCustomerReference = dbProps.map(
              (property) => property.customerReferenceNumber
            );

            // Now filter properties where isSevdeskProperty is 0
            const dbPropsCustomerReferences2 = filteredProperties.filter(
              (property) =>
                property.isSevdeskProperty === 0 &&
                !dbPropsCustomerReference.includes(property.id) // Exclude those whose customerReferenceNumber is the same a sevdesk property id
            );

            propertiesToSave = [...dbProps, ...dbPropsCustomerReferences2];
          }

          if (isObjekbetreuer && data?.assignedProperties) {
            propertiesToSave = filteredProperties.filter((property) =>
              data.assignedProperties?.some(
                (assignedProperty: string) => assignedProperty === property.id
              )
            );

            propertiesToSave = propertiesToSave.map((property) => {
              // Find the corresponding Sevdesk property
              const sevdeskProperty = sevdeskProperties.find(
                (sevProp) => sevProp.id === property.id
              );

              // If found, overwrite the street and city
              if (sevdeskProperty) {
                return {
                  ...property,
                  street: sevdeskProperty.street,
                  city: sevdeskProperty.city,
                };
              }

              return property;
            });
          }

          dispatch(userSliceActions.setCustomerProperties(propertiesToSave));

          if (selectedPropertyId == "") {
            dispatch(
              userSliceActions.setSelectedPropertyId(propertiesToSave[0]?.id)
            );
            dispatch(userSliceActions.setSelectedProperty(propertiesToSave[0]));
          }
          setLoading(false);
        }
      );
    });
  }, [dispatch, selectedPropertyId]);

  return (
    <AppLayout>
      <div id="scrollable-wrapper" className={styles["scrollable-wrapper"]}>
        {isDesktop && (
          <p className={styles["dashboard-title"]}>
            <Dashboard /> {t("LastServices")}
          </p>
        )}
        {!loading && (
          <div className={styles["tabs"]}>
            <div className={styles["buttons"]}>
              {SUB_MENU_BUTTONS.map((button, i) => (
                <SubMenuButton
                  currentTab={currentTab}
                  value={button}
                  onSetTab={() => handleChangeTab(button)}
                  key={i}
                />
              ))}
            </div>

            {currentTab === "PastCustomerVisits" && <LastServices />}
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default CustomerDashboard;
