import { t } from "i18next";

import { splitDateTime } from "../../services/DateTimeService";

import styles from "./deficiency-details.module.css";

type DeficiencyDetailsProps = {
  deficiencyType: string;
  deficiencySubType: string;
  date: string | undefined;
  propertyArea: string;
  location: string;
  responsibility: string;
};

export const DeficiencyDetails = ({
  deficiencyType,
  deficiencySubType,
  date,
  propertyArea,
  location,
  responsibility,
}: DeficiencyDetailsProps): JSX.Element => {
  const responsibilityText =
    responsibility === "exterminator" ? "SBK" : t("Customer");

  return (
    <div className={styles["deficiency-details"]}>
      <div className={styles["deficiency-details__first-row"]}>
        <div className={styles["deficiency-details__first-row--type"]}>
          <button className={styles["deficiency-details__circle"]}>
            {splitDateTime(date ?? "").date}
          </button>

          <span className={styles["deficiency-details__type"]}>
            {deficiencyType ?? "N/A"}
          </span>
        </div>

        <span className={styles["deficiency-details__type"]}>
          {deficiencySubType ?? "N/A"}
        </span>

        <span className={styles["deficiency-details__location"]}>
          {propertyArea}, {location}
        </span>

        <p className={`${styles["deficiency-details__responsibility"]}`}>
          {responsibilityText}
        </p>
      </div>
    </div>
  );
};
