import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useDeficiencyRedirect } from "../../hooks/useDeficiencyRedirect";
import { SubMenuButton } from "../SubMenuButton/SubMenuButton";

import { GroupedDeficiencies } from "./Deficiencies";
import styles from "./deficiencies.module.css";
import { DeficiencySwipe } from "./DeficiencySwipe";

interface CustomersViewProps {
  groupedDeficiencies: GroupedDeficiencies;
  handleDelete: (id: string) => void;
}

const SUB_MENU_BUTTONS: string[] = ["OpenReports", "ResolvedReports"];

const CustomersView = ({
  groupedDeficiencies,
  handleDelete,
}: CustomersViewProps) => {
  const { handleRedirect } = useDeficiencyRedirect();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState<string>("OpenReports");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setCurrentTab(tab);
    }
  }, [location.search]);

  const handleChangeTab = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <div className={styles["tabs"]}>
        <div className={styles["buttons"]}>
          {SUB_MENU_BUTTONS.map((button, i) => (
            <SubMenuButton
              currentTab={currentTab}
              value={button}
              onSetTab={() => handleChangeTab(button)}
              key={i}
            />
          ))}
        </div>

        {currentTab === "OpenReports" &&
          groupedDeficiencies.open.length > 0 &&
          groupedDeficiencies.open.map((deficiency) => (
            <DeficiencySwipe
              isDeficiencyList
              deficiency={deficiency}
              handleDelete={handleDelete}
            />
          ))}

        {currentTab === "ResolvedReports" &&
          groupedDeficiencies.resolved.length > 0 &&
          groupedDeficiencies.resolved.map((deficiency) => (
            <DeficiencySwipe
              isDeficiencyList
              deficiency={deficiency}
              handleDelete={handleDelete}
              onClick={() => handleRedirect(deficiency)}
            />
          ))}
      </div>
    </>
  );
};

export default CustomersView;
