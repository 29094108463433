import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PropertyState {
  id: string;
  viewOnly: boolean;
}

const initialState: PropertyState = {
  id: "",
  viewOnly: false,
};

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setPropertyId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    clearPropertyId: (state) => {
      state.id = "";
    },
    setViewOnly: (state, action: PayloadAction<boolean>) => {
      state.viewOnly = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
});

export const propertyActions = propertySlice.actions;

export default propertySlice.reducer;
