import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAppointment } from "../../hooks/useAppointment";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { useRedirect } from "../../hooks/useRedirect";
import { RootState } from "../../store";
import { overviewAppointmentSliceActions } from "../../store/overview-appointment-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { Appointment } from "../../types/appointment";
import { AppointmentCard } from "../AppointmentCard/AppointmentCard";
import { DeficiencyCard } from "../DeficiencyCard/DeficiencyCard";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { NoData } from "../NoData/NoData";

import styles from "./last-services.module.css";

export const LastServices = () => {
  const scrollableWrapper = document.getElementById(
    "scrollable-wrapper"
  ) as HTMLElement;
  const { setDashboardUrlAndRedirect } = useRedirect();
  const dispatch = useDispatch();

  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);

  const propertyIdFromProperty = useSelector(
    (state: RootState) => state.property.id
  );
  const propertyIdFromAppointment = useSelector(
    (state: RootState) => state.appointment.propertyId
  );

  const propertyId = isViewOnly
    ? propertyIdFromProperty
    : propertyIdFromAppointment;

  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [queryParams, setQueryParams] = useState({
    "page[size]": 20,
    "page[number]": 1,
    "filter[untilDate]": tomorrow,
    sort: "-plannedStart",
    "filter[propertyId]": isCustomerAdmin ? selectedPropertyId : propertyId,
    include: "documents,incidents.propertyArea",
  });

  const {
    appointments: newAppointments,
    appointmentsMeta,
    loading,
  } = useAppointment(queryParams, true, true);

  const [isFetching, setIsFetching] = useInfiniteScroll(
    scrollableWrapper,
    hasMore
  );

  const handleServiceClicked = (appointment: Appointment) => {
    const isDeficiency = appointment.incidents.length > 0;

    dispatch(overviewAppointmentSliceActions.setAppointment(appointment));

    dispatch(activeTabsActions.setPreviousPath("/"));

    const url = isDeficiency
      ? `/deficiencies/${appointment.incidents[0].id}/overview/readonly`
      : `/services/${appointment.id}/services-overview/readonly`;
    setDashboardUrlAndRedirect(url);
  };

  useEffect(() => {
    setAppointments([]);
    setPage(1);
    setQueryParams((prevParams) => ({
      ...prevParams,
      "page[number]": 1,
      "filter[propertyId]": isCustomerAdmin ? selectedPropertyId : propertyId,
    }));
  }, [selectedPropertyId, isCustomerAdmin, propertyId]);

  useEffect(() => {
    if (newAppointments) {
      const uniqueAppointments = new Set(appointments.map((app) => app.id));
      const filteredAppointments = newAppointments.filter(
        (appointment) => !uniqueAppointments.has(appointment.id)
      );

      if (filteredAppointments.length === 0) {
        return;
      }

      setAppointments((prevAppointments) => {
        if (queryParams["page[number]"] === 1) {
          return filteredAppointments;
        }
        return [...prevAppointments, ...filteredAppointments];
      });
    }
  }, [newAppointments, selectedPropertyId, queryParams]);

  useEffect(() => {
    if (isFetching) {
      setPage((prevPage) => prevPage + 1);
      setIsFetching(false);
    }
  }, [isFetching]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      "page[number]": page,
      "filter[propertyId]": isCustomerAdmin ? selectedPropertyId : propertyId,
    });
  }, [page, selectedPropertyId, isCustomerAdmin]);

  useEffect(() => {
    if (appointmentsMeta) {
      setHasMore(appointmentsMeta?.currentPage < appointmentsMeta?.totalPages);
    }
  }, [appointmentsMeta]);

  const renderAppointments = () => {
    if (loading && page === 1) {
      return <LoadingSpinner loading={loading} />;
    }

    const combinedAppointments = appointments
      .filter((appointment) => appointment.appointmentState === "completed")
      .map((appointment) => {
        if (appointment.incidents.length === 0) {
          return {
            ...appointment,
            type: "appointment",
            date: appointment.plannedStart,
          };
        }

        return {
          ...appointment,
          type: "deficiency",
          incident: appointment.incidents[0],
          propertyAreaLabel: appointment.incidents[0]?.propertyArea?.[0]?.label,
          date: appointment.incidents[0]?.dueDate,
        };
      })

      .sort((a, b) => moment(b.date).diff(moment(a.date)));

    if (combinedAppointments.length > 0) {
      return (
        <>
          {combinedAppointments.map((item) =>
            item.type === "appointment" ? (
              <AppointmentCard
                key={item.id}
                upcoming={false}
                appointment={item}
                onClick={() => handleServiceClicked(item)}
                color="blue"
              />
            ) : (
              <DeficiencyCard
                key={item.id}
                deficiency={item.incident!}
                propertyAreaLabel={
                  item?.incident?.propertyArea &&
                  item?.incident?.propertyArea[0]?.label
                }
                onClick={() => handleServiceClicked(item)}
              />
            )
          )}
          {loading && page > 1 && (
            <LoadingSpinner loading={loading} size={20} top="0" />
          )}
        </>
      );
    }
    return <NoData>{t("NoServices")}</NoData>;
  };

  return <div className={styles["services-block"]}>{renderAppointments()}</div>;
};
