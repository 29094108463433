import moment from "moment";
import { useTranslation } from "react-i18next";

import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import styles from "../../../styles/customer-monitors.module.css";

import { MonitorDetailsType } from "./Monitors";

type MonitorDetailsProps = {
  monitorDetails: MonitorDetailsType;
  lastServicesData: any[];
  lastServicesIncludes: any[];
  monitorProducts: any[];
  loading: boolean;
};

export const MonitorDetails = ({
  monitorDetails,
  lastServicesData = [],
  lastServicesIncludes = [],
  monitorProducts = [],
  loading = false,
}: MonitorDetailsProps) => {
  const [t] = useTranslation();
  const getBackgroundClass = (text: string) => {
    switch (text) {
      case "noInfestation":
        return styles["kein-befall"];
      case "infestation":
        return styles["befall"];
      case "maintenancePerformed":
        return styles["pflege"];
      case "unreachableOrMissing":
        return styles["nicht"];
      default:
        return "";
    }
  };
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return moment(date).format("DD.MM.");
  };

  const capitalizeFirstLetter = (text: string): string => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <div className={styles["monitor-details"]}>
      {loading ? (
        <LoadingSpinner loading={loading} size={15}></LoadingSpinner>
      ) : (
        <>
          <p className={styles["monitor-details__p-bold"]}>
            {monitorDetails.name} {monitorDetails.number},{" "}
            <span className={styles["monitor-details__p"]}>
              {monitorDetails.location}
            </span>
          </p>

          {monitorDetails.isTemporary && (
            <div className={styles["info-button"]}>Temporär</div>
          )}

          <div className={styles["loading-div"]}>
            <div>
              <p className={styles["gray-p"]}>Aktuelles Material:</p>
              {Array.isArray(monitorProducts) && monitorProducts.length > 0 ? (
                monitorProducts.map((product: any, index: number) => (
                  <div key={index} className={styles["materials-item"]}>
                    <p>{product.name}</p>
                    <p>{product.quantity}</p>
                  </div>
                ))
              ) : (
                <p>Keine Produkte verfügbar</p>
              )}
            </div>
            <div>
              <p className={styles["gray-p"]}>Letzte Kundenbesuche:</p>
              {Array.isArray(lastServicesData) &&
              lastServicesData.length > 0 ? (
                lastServicesData.map((service: any, index: number) => (
                  <div key={index} className={styles["last-service-item"]}>
                    <div className={styles["date"]}>
                      {formatDate(service.changedAt)}
                    </div>
                    <div className={styles["type"]}>
                      {lastServicesIncludes[index]?.appointment
                          ?.typeOfAppointment === "auftrag" ? "Bekämpfung" : capitalizeFirstLetter(
                        lastServicesIncludes[index]?.appointment
                          ?.typeOfAppointment
                      )}
                    </div>
                    <div
                      className={`${styles["type2"]} ${getBackgroundClass(
                        service.eventType
                      )}`}
                    >
                      {t(capitalizeFirstLetter(service.eventType))}
                    </div>
                  </div>
                ))
              ) : (
                <p>Keine Kundenbesuche vorhanden</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
