import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import JobService, { JobQueryParams } from "../../api/JobService";
import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import { MaterialsList } from "../../components/MaterialsList/MaterialsList";
import NoProductDocumentsModal from "../../components/Modal/NoProductDocumentsModal";
import { PropertyAreaMonitors } from "../../components/PropertyAreaMonitors/PropertyAreaMonitors";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { appStateActions } from "../../store/app-state-slice";
import { checkIsRunning } from "../../store/clock-slice";
import { creatingDocumentationActions } from "../../store/documentation-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/services-overview.module.css";
import { AppointmentUpdateRequestPayload } from "../../types/appointment";
import { calculateTotalTime } from "../../utils/calculateTotalTime";
import { mapPropertyAreasWithMonitors } from "../../utils/mapPropertyAreas";

export const ServicesOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [jobId, setJobId] = useState("");
  const [textArea, setTextArea] = useState<string>("");
  const [propertyAreaMonitors, setPropertyAreaMonitors] = useState<any[]>([]);
  const [webProducts, setWebProducts] = useState<any>(null);
  const [sevdeskProducts, setSevdeskProducts] = useState<any>(null);
  const [products, setProducts] = useState<any>(null);
  const [infestationCountPerArea, setInfestationCountPerArea] = useState<{
    [key: string]: number;
  }>({});
  const [loading, setLoading] = useState(true);

  const appointment = useSelector((state: RootState) => state.appointment);
  const user = useSelector((state: RootState) => state.user.currentUser.user);

  const serviceStarted = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );
  const servicesStep = useSelector(
    (state: RootState) => state.tabs.services.step
  );
  const isOnline = useSelector((state: RootState) => state.online.isOnline);

  const documentation = useSelector((state: RootState) => state.documentation);

  useEffect(() => {
    if (appointment.jobs.length > 0) {
      const copiedJobs = Array.from(appointment.jobs);
      const lastJobId = copiedJobs.pop();
      setJobId(lastJobId.id);
    }
  }, [appointment.jobs]);

  const fetchPropertyAreaMonitors = useCallback(async () => {
    if (appointment.propertyId) {
      try {
        const { data } = await PropertyService.GetProperty(
          appointment.propertyId
        );

        dispatch(appStateActions.addProperty(data));

        const propertyAreas = data.propertyAreas || [];
        const propertyAreaIds = propertyAreas.map((area) => area.id);

        const monitorsPromises = propertyAreaIds.map((propertyAreaId) =>
          AppointmentService.GetMonitorsWithVersions(
            appointment.id,
            propertyAreaId
          )
        );

        const monitorsResponses = await Promise.all(monitorsPromises);

        const monitorsData = monitorsResponses.map((response) =>
          response.data.filter(
            (monitor: any) => monitor.versions && monitor.versions.length > 0
          )
        );

        setPropertyAreaMonitors(
          mapPropertyAreasWithMonitors(propertyAreas, monitorsData)
        );

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [dispatch, appointment.propertyId]);

  const fetchGroupedMaterialsByJob = useCallback(async () => {
    if (jobId) {
      try {
        const { data } = await JobService.GetGroupedMaterialsByJob(jobId);

        if (data.webProducts) {
          setWebProducts(
            data.webProducts.map((p: any) => ({
              id: p.item.productId,
              name: p.item.product.name,
              quantity: p.quantity,
            }))
          );
        }

        if (data.sevdeskProducts) {
          setSevdeskProducts(
            data.sevdeskProducts.map((p: any) => ({
              id: p.item.id,
              name: p.item.name,
              quantity: p.quantity,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [jobId]);

  const fetchGroupedEventsByJob = useCallback(async () => {
    if (jobId) {
      try {
        const { data } = await JobService.GetGroupedEventsByJob(jobId);

        const infestationCountMap: { [key: string]: number } = {};

        data.forEach((areaData: any) => {
          const areaLabel = areaData.label;
          const infestationEvents = areaData.events.infestation;

          if (infestationEvents) {
            infestationCountMap[areaLabel] = infestationEvents.length;
          } else {
            infestationCountMap[areaLabel] = 0;
          }
        });

        setInfestationCountPerArea(infestationCountMap);
      } catch (error) {
        console.log(error);
      }
    }
  }, [jobId]);

  const fetchGroupedDeficienciesPerPropertyAreaByJob = useCallback(async () => {
    if (jobId) {
      const { firstEvent, lastEvent } = await calculateTotalTime(
        appointment.id
      );

      if (firstEvent && lastEvent) {
        const newQueryParams: JobQueryParams = {
          "filter[sinceDate]": moment(firstEvent.createdAt).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          "filter[untilDate]": moment(lastEvent.createdAt).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
        };

        try {
          const { data } =
            await JobService.GetGroupedDeficienciesPerPropertyAreaByJob(
              jobId,
              newQueryParams
            );

          dispatch(
            creatingDocumentationActions.setPropertyAreaDeficiencies(data)
          );
        } catch (error) {
          console.error("Error fetching grouped deficiencies:", error);
        }
      }
    }
  }, [dispatch, jobId]);

  const fetchGroupedEventsPerPropertyAreaByJob = useCallback(async () => {
    if (jobId) {
      try {
        const { data } =
          await JobService.GetGroupedEventsPerPropertyAreaByJob(jobId);

        dispatch(creatingDocumentationActions.setPropertyAreaEvents(data));
      } catch (error) {
        console.log(error);
      }
    }
  }, [jobId]);

  const fetchGroupedMonitorsPerPropertyAreaByAppointment =
    useCallback(async () => {
      if (appointment.id) {
        try {
          const { data } = await AppointmentService.GetAdjustedMonitors(
            appointment.id
          );

          dispatch(
            creatingDocumentationActions.setPropertyAreaAdjustedMonitors(data)
          );
        } catch (error) {
          console.log(error);
        }
      }
    }, [appointment.id]);

  useEffect(() => {
    if (isOnline) {
      fetchPropertyAreaMonitors();
      fetchGroupedMaterialsByJob();
      fetchGroupedEventsByJob();
      fetchGroupedEventsPerPropertyAreaByJob();
      fetchGroupedDeficienciesPerPropertyAreaByJob();
      fetchGroupedMonitorsPerPropertyAreaByAppointment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchGroupedEventsByJob,
    fetchGroupedMaterialsByJob,
    fetchPropertyAreaMonitors,
    fetchGroupedEventsPerPropertyAreaByJob,
    fetchGroupedDeficienciesPerPropertyAreaByJob,
    fetchGroupedMonitorsPerPropertyAreaByAppointment,
    isOnline,
    appointment,
  ]);

  useEffect(() => {
    const combinedProducts = [
      ...(webProducts || []),
      ...(sevdeskProducts || []),
    ];

    setProducts(combinedProducts);
    dispatch(creatingDocumentationActions.setProducts(combinedProducts));
  }, [webProducts, sevdeskProducts, dispatch]);

  const handleBackButtonClicked = () => {
    if (servicesStep === 1) {
      goOneStepBack(navigate);
    } else {
      dispatch(activeTabsActions.setServicesStep(1));
    }
  };

  const handleFirstContinueClick = () => {
    dispatch(activeTabsActions.setServicesStep(2));
  };

  const handleSecondContinueClick = () => {
    const updateAppointmentPayload: AppointmentUpdateRequestPayload = {
      comment: documentation.remark,
    };

    if (documentation.remark != "") {
      AppointmentService.UpdateAppointment(
        appointment.id,
        updateAppointmentPayload
      );
    }

    dispatch(
      creatingDocumentationActions.setAttachedTo({
        type: "Appointment",
        id: appointment.id,
      })
    );
    navigate(`/services/${appointment.id}/services-completion`);
  };

  const handleTextareaChange = (note: string) => {
    setTextArea(note);
    dispatch(creatingDocumentationActions.setRemark(note));
  };

  const propertyAreaMonitorsSortedByInfestationCount = useMemo(() => {
    return [...propertyAreaMonitors].sort((a, b) => {
      const infestationCountA = infestationCountPerArea[a.propertyArea] || 0;
      const infestationCountB = infestationCountPerArea[b.propertyArea] || 0;
      return infestationCountB - infestationCountA;
    });
  }, [propertyAreaMonitors, infestationCountPerArea]);

  return (
    <AppLayout hideHeader>
      <div>
        <NoProductDocumentsModal />

        <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />

        <AppointmentDetails isServicesOverview={true} />
        {servicesStep === 1 && loading && <LoadingSpinner loading={loading} />}
        {servicesStep === 1 ? (
          <div className={styles["content-wrapper"]}>
            {!loading && (
              <>
                <MaterialsList
                  title={"Arbeits-, Material- und Präparateeinsatz"}
                  data={products}
                  readOnly={true}
                />

                <div className={styles.monitors}>
                  <PropertyAreaMonitors
                    monitors={propertyAreaMonitorsSortedByInfestationCount}
                    startService={() => {}}
                    isServiceStarted={serviceStarted}
                    isServicesOverview={true}
                    jobId={jobId}
                    infestationCountPerArea={infestationCountPerArea}
                  />
                </div>
              </>
            )}

            <FloatingButtons>
              <CustomButton
                color="gray"
                width={80}
                onClick={handleFirstContinueClick}
              >
                {t("Continue")}
              </CustomButton>
            </FloatingButtons>
          </div>
        ) : (
          <div className={styles["content-wrapper"]}>
            <span className={styles["title"]}>{t("Remark")}</span>

            <CustomTextArea
              placeholder="Zusätzlicher Text"
              onTextareaChange={handleTextareaChange}
              defaultValue={textArea}
            />

            <FloatingButtons>
              <CustomButton
                color="gray"
                width={80}
                onClick={handleSecondContinueClick}
              >
                {t("Continue")}
              </CustomButton>
            </FloatingButtons>
          </div>
        )}
      </div>
    </AppLayout>
  );
};
