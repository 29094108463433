import { MouseEventHandler } from "react";

import feedback from "./feedback.png";

type FeedbackIconProps = {
  handleFeedbackClicked: MouseEventHandler<HTMLImageElement>;
};

export const FeedbackIcon = ({ handleFeedbackClicked }: FeedbackIconProps) => (
  <img src={feedback} alt="Remove icon" onClick={handleFeedbackClicked} />
);
