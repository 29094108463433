import classNames from "classnames";
import { DefaultTFuncReturn } from "i18next";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useScreenSize } from "../../hooks/useScreenSize";
import { LeftArrowIcon } from "../../icons/LeftArrowIcon/LeftArrowIcon";
import { ReturnArrowIcon } from "../../icons/ReturnArrowIcon/ReturnArrowIcon";
import { RootState } from "../../store";
import { checkIsRunning } from "../../store/clock-slice";
import { CustomButton } from "../CustomButton/CustomButton";

import styles from "./back-button-header.module.css";

type ButtonIcon = "return" | "left";

type BackButtonHeaderProps = {
  handleBackButtonClicked: MouseEventHandler<HTMLImageElement>;
  handleFetchDocument?: MouseEventHandler<HTMLButtonElement>;
  backButtonForModal?: boolean;
  isDeficiency?: boolean;
  isEditedByCustomer?: boolean;
  showDocumentation?: boolean;
  icon?: ButtonIcon;
  text?: DefaultTFuncReturn;
};

export const BackButtonHeader = ({
  handleBackButtonClicked,
  handleFetchDocument,
  backButtonForModal = false,
  isDeficiency = false,
  isEditedByCustomer = false,
  showDocumentation = true,
  icon = "return",
  text,
}: BackButtonHeaderProps): JSX.Element => {
  const [t] = useTranslation();
  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const appointment = useSelector((state: RootState) => state.appointment);

  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const { isDesktop } = useScreenSize();

  const classes = classNames(styles["back-header"], {
    [styles["back-header__green"]]: isRunning && !backButtonForModal,
    [styles["back-header__white"]]: !isRunning,
    [styles["back-header__white--modal"]]: isRunning && backButtonForModal,
  });

  return (
    <div className={classes}>
      <div>
        {icon === "return" ? (
          <ReturnArrowIcon handleBackButtonClicked={handleBackButtonClicked} />
        ) : (
          <div className={styles["back__title"]}>
            <LeftArrowIcon handleBackButtonClicked={handleBackButtonClicked} />
            {text && <span>{text}</span>}
          </div>
        )}

        {isCustomerAdmin && isDesktop && isDeficiency && (
          <p className={styles["deficiency-type__title"]}>
            {t("CreateDeficiency")}
          </p>
        )}
      </div>
      {isDesktop && !isEditedByCustomer && showDocumentation && (
        <div className={styles["back-header__download"]}>
          <p>{t("DownloadDocumentation")}</p>
          <CustomButton
            color="gray"
            icon="document"
            width={48}
            onClick={handleFetchDocument}
          />
        </div>
      )}
    </div>
  );
};
