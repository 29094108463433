import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useDownloadFiles } from "../../hooks/useDownloadFiles";
import { DownloadIcon2 } from "../../icons/DownloadIcon2/DownloadIcon2";
import { generateUUIDFromID } from "../../services/UUIDGeneratorService";
import { productSliceActions } from "../../store/products-slice";
import { MinimalProduct } from "../../types/product";
import { QuantityButton } from "../QuantityButton/QuantityButton";

import styles from "./material-list-item.module.css";

type MaterialListItemProps = {
  product: MinimalProduct;
  readOnly?: boolean;
};

export const MaterialListItem = ({
  product,
  readOnly,
}: MaterialListItemProps) => {
  const { id, name, quantity } = product;
  const dispatch = useDispatch();
  const { fetchProductDocuments } = useDownloadFiles();
  const productId = generateUUIDFromID(id.toString());
  const [showInput, setShowInput] = useState(product.showInput);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(quantity > 0 ? quantity : "");

  const handleQuantity = (id: string | number, increment: boolean) => {
    if (increment) {
      dispatch(productSliceActions.increment(id));
    } else {
      dispatch(productSliceActions.decrement(id));
    }
  };

  const handleSpanClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    dispatch(
      productSliceActions.incrementFor({
        id,
        amount: 0, // delete incremented values
      })
    );
    setShowInput(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      productSliceActions.incrementFor({
        id,
        amount: Number(e.target.value),
      })
    );

    dispatch(
      productSliceActions.showInput({
        id,
        showInput: true,
      })
    );

    const validValue = e.target.value.replace(/[^0-9.]/g, "");
    setInputValue(validValue);
  };

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);
  return (
    <li
      className={`${styles["materials-list__card"]} ${
        readOnly && styles["materials-list__card--readonly"]
      }`}
      onClick={() => {
        if (inputValue == "") {
          setShowInput(false);
        }
      }}
    >
      <div className={styles["materials-list__left"]}>
        {readOnly && (
          <div className={styles["materials-list__quantity-readOnly"]}>
            <span>{quantity}</span>
          </div>
        )}
        <span className={styles["materials-list__item"]}>{name}</span>
      </div>
      {readOnly && (
        <div className={styles["materials-list__quantity-readOnly"]}>
          <DownloadIcon2
            handleDownloadButtonClicked={() => {
              fetchProductDocuments(productId);
            }}
          />
        </div>
      )}

      {!readOnly && !showInput && (
        <div className={styles["materials-list__quantity"]}>
          <QuantityButton
            active={quantity > 0}
            icon="decrement"
            onClick={() => handleQuantity(id, false)}
          />
          <span
            className={styles["span__quantity"]}
            onClick={(e) => {
              handleSpanClick(e);
            }}
          >
            {product.quantity}
          </span>
          <QuantityButton
            active={quantity > 0}
            icon="increment"
            onClick={() => handleQuantity(id, true)}
          />
        </div>
      )}

      {showInput && (
        <div className={styles["materials-list__quantity"]}>
          <input
            className={styles["input__quantity"]}
            value={inputValue}
            ref={inputRef}
            type="number"
            onChange={(e) => {
              handleInputChange(e);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
      )}
    </li>
  );
};
