import { Deficiency } from "../../types/deficiencies";
import { DeficiencyCard } from "../DeficiencyCard/DeficiencyCard";
import { Swipeable2 } from "../Swipeable2/Swipeable2";

interface DeficiencySwipeProps {
  deficiency: Deficiency;
  handleDelete: (id: string) => void;
  onClick?: () => void;
  isDeficiencyList?: boolean;
}

export const DeficiencySwipe = ({
  deficiency,
  handleDelete,
  onClick,
  isDeficiencyList = false,
}: DeficiencySwipeProps) => (
  <Swipeable2
    key={`today-${deficiency.id}`}
    onDelete={() => handleDelete(deficiency.id)}
  >
    <DeficiencyCard
      deficiency={deficiency}
      onClick={onClick}
      isDeficiencyList={isDeficiencyList}
    />
  </Swipeable2>
);
