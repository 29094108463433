import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BackEndService from "../../api/BackEndService";
import MonitorServiceAction from "../../api/MonitorService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomCollapse } from "../../components/CustomCollapse/CustomCollapse";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { MultiImageUploader2 } from "../../components/MultiImageUploader2/MultiImageUploader2";
import { ServiceInfoBox } from "../../components/ServiceInfoBox/ServiceInfoBox";
import { useHandleServiceImages } from "../../hooks/useHandleServiceImages";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { goOneStepBack } from "../../services/NavigationService";
import { RootState } from "../../store";
import { imageUploaderActions } from "../../store/image-uploader-slice";
import { creatingServiceActions } from "../../store/job-slice";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { productSliceActions } from "../../store/products-slice";
import { MonitorEvent } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/unreachable.module.css";
import { ResponseMeta, ResponseWarningQueries } from "../../types/response";
import { filterProducts } from "../../utils/filterProducts";

export const MaintenanceService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const { handleImageUploaded, handleImageDelete } = useHandleServiceImages();
  const appointment = useSelector((state: RootState) => state.appointment);
  const service = useSelector((state: RootState) => state.service);
  const products = useSelector((state: RootState) => state.products);
  const monitor = useSelector((state: RootState) => state.monitor.monitor);
  const [imageLoading, setImageLoading] = useState(false);
  const servicedMonitors = useSelector(
    (state: RootState) => state.servicedMonitors.servicedMonitors
  );
  const monitorEvents = useSelector(
    (state: RootState) => state.servicedMonitors.monitorEvents
  );

  const [monitorEventId, setMonitorEventId] = useState("");
  const [requestLoading, setRequestLoading] = useState(false);

  const handleTextareaChange = (note: string) => {
    dispatch(creatingServiceActions.addComment(note));
  };

  const resetValues = () => {
    dispatch(creatingMonitorActions.resetState());
    dispatch(productSliceActions.resetState());
    dispatch(creatingServiceActions.resetState());
    dispatch(imageUploaderActions.resetState());
  };

  const handleClockClick = () => {
    resetValues();
  };

  const returnToDashboard = useCallback(() => {
    dispatch(activeTabsActions.setDashbaordUrl(""));
    dispatch(activeTabsActions.setMonitorsStep(2));
    resetValues();

    navigate(`/properties/${appointment.id}/show`);
  }, [appointment, dispatch, navigate]);

  const handleLoadingChange = (loading: boolean) => {
    setImageLoading(loading);
  };

  const { sevDeskProducts, usedProducts } = filterProducts(products);

  const createMonitorEvent = useCallback(async () => {
    if (usedProducts.length === 0 && sevDeskProducts?.length === 0) return;
    try {
      setRequestLoading(true);
      let response: { data: any } & ResponseMeta & ResponseWarningQueries;
      const payload = {
        monitorId: monitor!.id,
        eventType: service.eventType,
        jobId: appointment?.jobs?.slice(-1).pop().id,
        comment: service.comment,
        usedProducts: usedProducts,
        sevdesk_products: sevDeskProducts,
      };

      if (servicedMonitors?.includes(monitor!.id)) {
        response = await MonitorServiceAction.UpdateMonitorEvent(
          monitorEventId,
          payload
        );
      } else {
        response = await MonitorServiceAction.CreateMonitorEvent(payload);
      }
      if (response.data) {
        if (response.data) {
          if (service.images.length > 0) {
            const imageUploadPromises = service.images.map((image) => {
              return BackEndService.AddDocument({
                documentType: "userUpload",
                fileName: image.name,
                isInternal: true,
                media: image.uuid,
                attachedTo: {
                  type: "MonitorEvent",
                  id: response.data.id,
                },
              });
            });

            // Wait for all image uploads to complete before proceeding
            await Promise.all(imageUploadPromises).then(() => response);
          }
          returnToDashboard();
          // Clear state here
          dispatch(creatingServiceActions.resetState());
          dispatch(productSliceActions.resetState());
          setRequestLoading(false);
        }
      }
    } catch (error) {
      setRequestLoading(false);
      setError(() => t("MonitorIsRemoved"));
      console.error(error);
    }
  }, [
    products,
    monitor,
    service.eventType,
    service.comment,
    appointment?.jobs,
    sevDeskProducts,
    usedProducts,
    dispatch,
    service.images.length,
    returnToDashboard,
  ]);

  const handleMaterialsClicked = () => {
    navigate(`/products`);
  };

  const handleBackButtonClicked = () => {
    goOneStepBack(navigate);
    resetValues();
  };

  useEffect(() => {
    setMonitorEventId(
      monitorEvents.find(
        (event: MonitorEvent) => event.monitorId === monitor.id
      )?.id ?? ""
    );
  }, [monitorEvents]);

  return (
    <AppLayout hideHeader>
      <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />

      <AppointmentDetails handleClockClickReset={handleClockClick} />

      <div className={styles["content-wrapper"]}>
        <ServiceInfoBox data={monitor} typeOfService="Pflege" color="blue" />

        <CustomCollapse onClick={handleMaterialsClicked} products={products}>
          Arbeits-, Material- und Präparateeinsatz
        </CustomCollapse>

        <MultiImageUploader2
          images={service.images}
          onImageUpload={handleImageUploaded}
          onDeleteImage={handleImageDelete}
          onLoadingChange={handleLoadingChange}
        />

        <CustomTextArea
          placeholder="Zusätzlicher Text"
          onTextareaChange={handleTextareaChange}
          defaultValue={service.comment}
        />

        {error && <div className={styles["error-message"]}>{error}</div>}

        <FloatingButtons>
          <CustomButton
            color="green"
            onClick={createMonitorEvent}
            width={60}
            disabled={
              (!usedProducts.length && !sevDeskProducts?.length) || imageLoading
            }
            loading={requestLoading}
          >
            {t("Confirm")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
