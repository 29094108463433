import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useHandleServiceImages } from "../../hooks/useHandleServiceImages";
import { RootState } from "../../store";
import { creatingServiceActions } from "../../store/job-slice";
import { Pest } from "../../types/pest";
import { Pests } from "../../types/service";
import { CustomCollapse } from "../CustomCollapse/CustomCollapse";
import { MultiImageUploader2 } from "../MultiImageUploader2/MultiImageUploader2";
import { QuantityButton } from "../QuantityButton/QuantityButton";

import styles from "./issues-type.module.css";

type AnimalFoundProps = {
  onLoadingChange?: (loading: boolean) => void;
};

export const AnimalFound = ({ onLoadingChange }: AnimalFoundProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleImageUploaded, handleImageDelete } = useHandleServiceImages();
  const service = useSelector((state: RootState) => state.service);
  const products = useSelector((state: RootState) => state.products);
  const pests = useSelector((state: RootState) => state.settings.pests);
  // eslint-disable-next-line no-unused-vars
  const [imageLoading, setImageLoading] = useState(false);
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({});
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const inputRef = useRef<HTMLInputElement>(null);

  const handleMaterialsClicked = () => {
    navigate(`/products`);
  };

  const handleComment = (value: string) => {
    dispatch(creatingServiceActions.addComment(value));
  };

  const getName = (id: string) => {
    return pests.find((pest: Pest) => pest.id === id)?.name || "";
  };

  const handleLoadingChange = (loading: boolean) => {
    setImageLoading(loading);
    if (onLoadingChange) onLoadingChange(loading);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const validValue = e.target.value.replace(/[^0-9.]/g, "");
    setInputValues((prev) => ({ ...prev, [id]: validValue }));
    dispatch(
      creatingServiceActions.incrementFor({
        id,
        quantity: Number(validValue),
      })
    );
  };

  const handleSpanClick = (
    e: React.MouseEvent<HTMLSpanElement>,
    id: string
  ) => {
    e.stopPropagation();
    setShowInput((prev) => ({ ...prev, [id]: true }));
  };

  useEffect(() => {
    if (service.savedPests.length) {
      dispatch(creatingServiceActions.addPests(service.savedPests));
    } else {
      dispatch(
        creatingServiceActions.addPests(
          pests.map((pest: any) => ({
            pestId: pest.id,
            quantity: 0,
            quantityAdult: null,
            quantityNymph: null,
          }))
        )
      );
    }
  }, [pests, dispatch, service.savedPests]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["infastation-type"]}>
        <p className={styles["infastation-type__title"]}>
          {t("InfestationType")}
        </p>

        <div className={styles["infastation-type__pests"]}>
          {service.pests?.map((pest: Pests) => (
            <div
              key={pest.pestId}
              className={styles["infastation-type__pests__item"]}
              onClick={() => {
                if (
                  inputValues[pest.pestId] === "" ||
                  inputValues[pest.pestId] === undefined
                ) {
                  setShowInput((prev) => ({ ...prev, [pest.pestId]: false }));
                }
              }}
            >
              <p>{getName(pest.pestId)}</p>
              {!showInput[pest.pestId] && (
                <div className={styles["pests-list__quantity"]}>
                  <QuantityButton
                    active={pest.quantity! > 0}
                    icon="decrement"
                    onClick={() =>
                      dispatch(
                        creatingServiceActions.decrement({ id: pest.pestId })
                      )
                    }
                  />
                  <span
                    className={styles["span__quantity"]}
                    onClick={(e) => handleSpanClick(e, pest.pestId)}
                  >
                    {pest.quantity}
                  </span>
                  <QuantityButton
                    active={pest.quantity! > 0}
                    icon="increment"
                    onClick={() =>
                      dispatch(
                        creatingServiceActions.increment({ id: pest.pestId })
                      )
                    }
                  />
                </div>
              )}

              {showInput[pest.pestId] && (
                <div className={styles["pests-list__quantity"]}>
                  <input
                    className={styles["input__quantity"]}
                    value={inputValues[pest.pestId] || ""}
                    ref={inputRef}
                    type="number"
                    onChange={(e) => handleInputChange(e, pest.pestId)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              )}
            </div>
          ))}

          <div className={styles["infastation-type__pests__other"]}>
            <p>{t("Sonstige")}</p>
            <textarea
              value={service.comment}
              onChange={(e) => handleComment(e.target.value)}
              placeholder={`${t("AdditionalText")}`}
              className={styles["infastation-type__pests__other--textarea"]}
            />
          </div>
        </div>
      </div>

      <CustomCollapse onClick={handleMaterialsClicked} products={products}>
        Arbeits-, Material- und Präparateeinsatz
      </CustomCollapse>

      <MultiImageUploader2
        images={service.images}
        onImageUpload={handleImageUploaded}
        onDeleteImage={handleImageDelete}
        onLoadingChange={handleLoadingChange}
      />
    </div>
  );
};
