import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DeficiencyService from "../../api/DeficiencyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomCollapseWithChildren } from "../../components/CustomCollapseWithChildren/CustomCollapseWithChildren";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { DeficiencyDetails } from "../../components/DeficiencyDetails/DeficiencyDetails";
import { DeficiencyType } from "../../components/DeficiencyType/DeficiencyType";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { ResourceImages } from "../../components/ResourceImages/ResourceImages";
import { TabTypeSelection } from "../../components/TabTypeSelection/TabTypeSelection";
import { TechniciansList } from "../../components/TechniciansList/TechniciansList";
import { useDownloadFiles } from "../../hooks/useDownloadFiles";
import { useRedirect } from "../../hooks/useRedirect";
import { useService } from "../../hooks/useService";
import { useUnauthorizedAccess } from "../../hooks/useUnauthorizedAccess";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { checkIsRunning } from "../../store/clock-slice";
import { deficiencyServiceActions } from "../../store/deficiency-service-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/deficiency-insight.module.css";
import { Appointment } from "../../types/appointment";
import { Deficiency } from "../../types/deficiencies";
import { ImageProps } from "../../types/image";
import { PropertyArea } from "../../types/property";
import { Technician } from "../../types/user";
import { getDeficiencyType } from "../../utils/getDeficiencyType";

type DeficiencyStateType = {
  data: Deficiency;
  includes?: {
    propertyArea?: PropertyArea[];
    appointments?: Appointment[];
    documents?: ImageProps[];
  };
};

const DeficiencyInsight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deficiencyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [note, setNote] = useState("");
  const { setDashboardUrlAndRedirect } = useRedirect();

  const appointment = useSelector((state: RootState) => state.appointment);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const previousPath = useSelector(
    (state: RootState) => state.tabs.previousPath
  );
  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );
  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);

  const { startService } = useService(appointment);

  const [deficiency, setDeficiency] = useState<DeficiencyStateType | null>(
    null
  );
  useUnauthorizedAccess(deficiency?.data);

  const { type, subType } = getDeficiencyType(
    deficiency?.data.deficiencyType,
    deficiency?.data.deficiencySubType
  );

  const activeTab =
    deficiency?.data.responsibility === "exterminator" ? "SBK" : "Kunde";

  const { closeModal } = useDownloadFiles();

  const isEmpty = useSelector(
    (state: RootState) => state.technicianDocuments.empty
  );

  const handleBackButtonClicked = () => {
    if (isCustomerAdmin) {
      return setDashboardUrlAndRedirect("/deficiencies");
    } else if (previousPath === "/") {
      dispatch(activeTabsActions.setDashbaordUrl(""));
      navigate("/");
    } else {
      navigate(-1);
    }
    dispatch(deficiencyServiceActions.resetState());
  };

  useEffect(() => {
    if (!deficiencyId) {
      return;
    }
    setLoading(true);
    DeficiencyService.GetDeficiency(deficiencyId)
      .then((deficiency) => {
        setDeficiency(deficiency);
      })
      .catch((error) => {
        console.error(error);
        navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [deficiencyId]);

  const isExterminatorResponsibility =
    deficiency?.data.responsibility === "exterminator";

  const location = `${deficiency?.data.propertyArea[0].label}, ${deficiency?.data.location}`;

  const propertyAreaMap = useMemo(() => {
    const [propertyArea] = deficiency?.includes?.propertyArea || [];

    return propertyArea?.map;
  }, [deficiency?.includes?.propertyArea]);

  const technicians: Technician[] =
    deficiency?.includes?.appointments?.[0]?.technicians ?? [];

  const images = deficiency?.includes?.documents ?? [];

  const handleFixDeficiency = () => {
    if (isRunning) {
      setModalMessage("AnotherServiceInProgress");
    } else if (isCustomerAdmin) {
      setModalMessage("ModalEditCV");
      setNote("ModalEditCVNote");
    } else {
      setModalMessage("FixMangel");
      setNote("WorkingHoursBackgroundCount");
    }
    setIsModalOpen(true);
  };

  const handleStartServiceOnMangel = () => {
    if (deficiency?.data) {
      dispatch(deficiencyServiceActions.setDeficiency(deficiency.data));
      if (images) dispatch(deficiencyServiceActions.setExistingImages(images));
      setIsModalOpen(false);

      if (isCustomerAdmin) {
        dispatch(deficiencyServiceActions.setIsCustomerEditing(true));
      } else {
        startService();
      }

      const url = `/deficiencies/${deficiencyId}/resolve`;
      setDashboardUrlAndRedirect(url);
    }
  };

  const monitorPinAbbr = useMemo(
    () => ({
      name: type?.slice(0, 3).toUpperCase(),
      number: "001",
    }),
    [type]
  );

  return (
    <AppLayout hideHeader={!isCustomerAdmin}>
      <Modal
        isOpen={isModalOpen}
        message={t(modalMessage)}
        onConfirm={() => setIsModalOpen(false)}
        {...(!isRunning && {
          note: t(note) ?? "",
          onCancel: () => setIsModalOpen(false),
          onConfirm: handleStartServiceOnMangel,
        })}
        {...(isCustomerAdmin && {
          confirmText: t("FixDeficiency") ?? "",
          deleteIconColor: "dark",
        })}
      />

      <Modal
        isOpen={isEmpty}
        message={t("NoInformation")}
        note={"NoAvailableDocuments"}
        iconType="noInfo"
        confirmText={"Back"}
        onConfirm={closeModal}
      />

      {isCustomerAdmin ? (
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          showDocumentation={false}
          icon="left"
          text={t("Open")}
        />
      ) : (
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          showDocumentation={false}
        />
      )}

      {isCustomerAdmin ? (
        <DeficiencyDetails
          deficiencyType={type}
          deficiencySubType={subType}
          date={deficiency?.data.createdAt}
          propertyArea={deficiency?.data.propertyArea[0].label ?? ""}
          location={deficiency?.data.location ?? ""}
          responsibility={deficiency?.data.responsibility ?? ""}
        />
      ) : (
        <AppointmentDetails />
      )}

      {loading && <LoadingSpinner loading={loading} />}

      {!loading && (
        <>
          <div className={styles["content-wrapper"]}>
            {!isCustomerAdmin && (
              <DeficiencyType type={type} subType={subType} />
            )}

            {isExterminatorResponsibility && (
              <TechniciansList technicians={technicians} />
            )}

            <TabTypeSelection
              readonly
              icon="accountDark"
              label="Verantwortung"
              tabs={["SBK", "Kunde"]}
              activeTab={activeTab}
              changeActiveTab={() => {}}
            />

            <div className={styles["map"]}>
              <CustomCollapseWithChildren
                active={!!propertyAreaMap}
                collapsedByDefault
                rightIcon="collapseArrow"
                label={t("Map")}
                defaultText={t("NoMapAvailable")}
              >
                <div className={styles["map-content"]}>
                  <PinCropMap
                    map={propertyAreaMap}
                    crop={
                      deficiency?.data.mapPosition?.mapPoint ?? { x: 1, y: 1 }
                    }
                    onCropChange={() => {}}
                    monitorPinAbbr={monitorPinAbbr}
                    isDraggable={false}
                    zoom={false}
                  />
                </div>
              </CustomCollapseWithChildren>
            </div>

            <CustomInput
              readonly
              label="Standortinformation"
              placeholder="Beschreibung"
              initialValue={location}
              hideTick
            />

            <CustomInput
              readonly
              label="Empfehlungen"
              placeholder="Beschreibung"
              initialValue={deficiency?.data.requiredActions[0] ?? ""}
              hideTick
            />

            <ResourceImages images={images} />

            <CustomTextArea
              readonly
              placeholder="Zusätzlicher Text"
              onTextareaChange={() => {}}
              defaultValue={deficiency?.data.comment ?? ""}
            />
          </div>

          {(isCustomerAdmin &&
            deficiency?.data.responsibility === "customer") ||
          (!isCustomerAdmin && !isViewOnly) ? (
            <FloatingButtons>
              <CustomButton
                color="green"
                icon="clock"
                iconPosition="left"
                width={80}
                onClick={handleFixDeficiency}
              >
                {t("FixDeficiency")}
              </CustomButton>
            </FloatingButtons>
          ) : null}
        </>
      )}
    </AppLayout>
  );
};

export default DeficiencyInsight;
