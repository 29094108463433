import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type MonitorSliceState = {
  monitorPosition: MonitorPosition;
  position: string | number;
  pinName: string;
  location: string;
};

type MonitorPosition = {
  x: number;
  y: number;
};

const initialState: MonitorSliceState = {
  monitorPosition: {
    x: 1,
    y: 1,
  },
  position: "",
  pinName: "",
  location: "",
};

export const adjustPinSlice = createSlice({
  name: "adjustPin",
  initialState: initialState,
  reducers: {
    setMonitorPosition(state, { payload }: PayloadAction<MonitorPosition>) {
      state.monitorPosition = payload;
    },
    setPosition(state, { payload }: PayloadAction<string | number>) {
      state.position = payload;
    },
    setPinName(state, { payload }: PayloadAction<string>) {
      state.pinName = payload;
    },
    setLocation(state, { payload }: PayloadAction<string>) {
      state.location = payload;
    },
    resetState(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const adjustPinActions = adjustPinSlice.actions;
