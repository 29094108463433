import { createSlice } from "@reduxjs/toolkit";

export type SearchState = {
  isExpanded: boolean;
  value: string;
};

const initialState: SearchState = {
  isExpanded: false,
  value: "",
};

export const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    setIsExpanded(state, action: { payload: boolean }) {
      state.isExpanded = action.payload;
    },
    setSearchValue(state, action: { payload: string }) {
      state.value = action.payload;
    },
    resetState(state: SearchState) {
      return { ...initialState };
    },
  },
});

export const searchActions = searchSlice.actions;
