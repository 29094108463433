import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { checkIsRunning, clockActions } from "../store/clock-slice";
import { activeTabsActions } from "../store/tabs-slice";
import { Appointment } from "../types/appointment";
import { calculateTotalTime } from "../utils/calculateTotalTime";

import { handleServiceEvent } from "./../utils/handleServiceEvent";

export const useService = (appointment: Appointment) => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );

  const clockodoUser = useSelector(
    (state: RootState) => state.user.clockodoUser
  );

  const [loading, setLoading] = useState(false);

  const startService = () => {
    setLoading(true);

    handleServiceEvent(appointment, isRunning, clockodoUser, user)
      .then(() => {
        if (appointment?.id) {
          return calculateTotalTime(appointment.id, user!.id);
        }

        return {
          totalTime: 0,
        };
      })
      .then(({ totalTime }) => {
        if (totalTime) {
          // Set timer
          dispatch(
            clockActions.setClock({
              isRunning: true,
              appointmentId: appointment?.id,
              employeeId: user!.id,
              currentTime: totalTime,
              initialTime: totalTime,
            })
          );
        }
      })
      .catch((error) => console.log("Some error happened", error))
      .finally(() => setLoading(false));
  };

  const stopService = (callback: () => void) => {
    setLoading(true);

    handleServiceEvent(appointment, isRunning, clockodoUser, user)
      .then(() => {
        // Reset timer
        dispatch(clockActions.resetClock());
        dispatch(activeTabsActions.setMonitorsStep(1));

        callback();
      })
      .catch((error) => console.log("Some error happened", error))
      .finally(() => setLoading(false));
  };

  return {
    loading,
    startService,
    stopService,
  };
};
