import { t } from "i18next";
import moment from "moment";
import { useSelector } from "react-redux";

import deficiencyTypes from "../../data/deficiency_types.json";
import { useRedirect } from "../../hooks/useRedirect";
import { RootState } from "../../store";
import { Deficiency } from "../../types/deficiencies";

import styles from "./deficiency-card.module.css";

type DeficiencyCardProps = {
  deficiency: Deficiency;
  onClick?: () => void;
  propertyAreaLabel?: string;
  isDeficiencyList?: boolean;
};

export const DeficiencyCard = ({
  deficiency,
  onClick,
  propertyAreaLabel,
  isDeficiencyList = false,
}: DeficiencyCardProps) => {
  const { setDashboardUrlAndRedirect } = useRedirect();
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const dueDateMoment =
    isCustomerAdmin && isDeficiencyList
      ? moment(deficiency.createdAt)
      : moment(deficiency.dueDate);
  const dueDate = dueDateMoment.format("DD.MM.");
  const dueTime = dueDateMoment.format("HH:mm");

  const deficiencyType = deficiencyTypes.find(
    (type) => type.deficiencyType === deficiency.deficiencyType
  );

  const responsibilityText =
    deficiency.responsibility === "exterminator" ? "SBK" : t("Customer");

  const handleCardClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    const url = `/deficiencies/${deficiency.id}/show`;
    setDashboardUrlAndRedirect(url);
  };

  return (
    <>
      <div className={styles["card"]} onClick={handleCardClick}>
        <div className={`${styles["card__circle"]}`}>{dueDate}</div>

        <div className={styles["card__info"]}>
          <div className={styles["card__info__title"]}>
            {deficiency.responsibility === "exterminator" && <i>{dueTime}</i>}
            <span>{deficiencyType?.name ?? "N/A"}</span>
          </div>

          <div className={styles["card__info__subtitle"]}>
            {propertyAreaLabel ? `${propertyAreaLabel}, ` : ""}
            {`${deficiency.propertyAreaLabel ?? ""}${
              deficiency.propertyAreaLabel ? ", " : ""
            }${deficiency.location ?? "/"}`}
          </div>

          <div className={`${styles["card__tags"]}`}>
            <p className={`${styles["card__type--responsibility"]}`}>
              {responsibilityText}
            </p>
            <p className={`${styles["card__type"]}`}>{t("Deficiency")}</p>
          </div>
        </div>
      </div>
    </>
  );
};
