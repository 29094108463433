import moment from "moment";

import { TrackingEventService } from "../api/TrackingEventService";

type ServiceStatusResponse = {
  isServiceInProgress: boolean;
  secondsSinceLastService: number;
};

export const getServiceStatus = async (
  appointmentId: string,
  employeeId?: string
): Promise<ServiceStatusResponse> => {
  try {
    const { data: trackingEvents } =
      await TrackingEventService.GetAllByAppointment(appointmentId);

    const filteredTrackingEvents = employeeId
      ? trackingEvents.filter((event) => event.employeeId === employeeId)
      : trackingEvents;

    const isInProgress = filteredTrackingEvents[0]?.eventType === "start";
    let secondsPassed = 0;

    if (isInProgress) {
      secondsPassed = calculateSecondsPassed(
        filteredTrackingEvents[0]?.eventTimestamp
      );
    }

    return {
      isServiceInProgress: isInProgress,
      secondsSinceLastService: secondsPassed,
    };
  } catch (error) {
    console.error(error);

    // Placeholder response
    return {
      isServiceInProgress: false,
      secondsSinceLastService: 0,
    };
  }
};

const calculateSecondsPassed = (eventTimestamp: string): number => {
  const eventTime = moment(eventTimestamp);
  const now = moment();

  return now.diff(eventTime, "seconds");
};
