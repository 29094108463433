import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ProductService from "../api/ProductService";

import { MinimalProduct, Product } from "./../types/product";

const initialState: MinimalProduct[] = [];

type IncrementPayload = {
  id: string | number;
  amount: number;
};

type ShowInput = {
  id: string | number;
  showInput: boolean;
};

export const fetchProducts = createAsyncThunk(
  "monitor/fetchProducts",
  async () => {
    try {
      const sevdeskProducts = await ProductService.GetSevdeskProducts();
      const appProducts = await ProductService.GetProducts();

      const sevdeskProductNames = new Set(
        sevdeskProducts.data.map((product: Product) =>
          product.name.toLowerCase()
        )
      );

      const filteredAppProducts = appProducts.data.filter(
        (product: Product) =>
          !sevdeskProductNames.has(product.name.toLowerCase())
      );

      const allProducts = [
        ...sevdeskProducts.data,
        ...filteredAppProducts,
      ].sort((a: Product, b: Product) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return allProducts;
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    increment(state, action: PayloadAction<string | number>) {
      const product = state.find((item) => item.id === action.payload);
      if (product) {
        product.quantity += 1;
      }
    },
    decrement(state, action: PayloadAction<string | number>) {
      const product = state.find((item) => item.id === action.payload);
      if (product && product.quantity > 0) {
        product.quantity -= 1;
      }
    },
    incrementFor(state, action: PayloadAction<IncrementPayload>) {
      const { id, amount } = action.payload;
      const product = state.find((item) => item.id === id);
      if (product) {
        product.quantity = amount;
      }
    },
    showInput(state, action: PayloadAction<ShowInput>) {
      const { id, showInput } = action.payload;
      const product = state.find((item) => item.id === id);
      if (product) {
        product.showInput = showInput;
      }
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      return action.payload.map(({ id, name, isSevDeskProduct }) => ({
        id,
        name,
        quantity: 0,
        isSevDeskProduct,
      }));
    });
  },
});

export const productSliceActions = productsSlice.actions;
