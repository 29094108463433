import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { StepOne } from "../../components/AppointmentForm/StepOne";
import { StepTwo } from "../../components/AppointmentForm/StepTwo";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { activeTabsActions } from "../../store/tabs-slice";
import { creatingTaskActions } from "../../store/task-slice";
import styles from "../../styles/create-appointment.module.css";

export const CustomerInsight = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const step = useSelector((state: RootState) => state.tabs.appointments.step);

  const handleGoBack = () => {
    if (step === 1) {
      dispatch(creatingTaskActions.resetAppointment());
      navigate("/");
    }

    if (step === 2) {
      dispatch(creatingTaskActions.setCustomer({ customer: "" }));
      dispatch(
        creatingTaskActions.setAdditionalDataCustomer({
          customer: {
            id: "",
            name: "",
          },
        })
      );
      dispatch(activeTabsActions.setAppointmentsStep(1));
    }
  };

  return (
    <AppLayout
      className={styles["tasks__list"]}
      withBackButton={true}
      onGoBack={handleGoBack}
    >
      <SubMenu showSearchButton={true}>{t("EnterTask")}</SubMenu>

      {step === 1 && <StepOne />}
      {step === 2 && <StepTwo />}
    </AppLayout>
  );
};
