import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ImageProps } from "../types/image";
import { MonitorEventType, Pests } from "../types/service";

export type JobSliceState = {
  importedPests: any[];
  pests: Pests[];
  savedPests: Pests[];
  comment: string;
  eatenQuantity?: number;
  eventType: MonitorEventType;
  reason?: string;
  images: ImageProps[];
};

const initialState: JobSliceState = {
  importedPests: [],
  comment: "",
  eatenQuantity: 0,
  eventType: "infestation",
  pests: [],
  savedPests: [],
  reason: "",
  images: [],
};

export const creatingServiceSlice = createSlice({
  name: "job",
  initialState: initialState,
  reducers: {
    addImportedPests(state: JobSliceState, action: { payload: string[] }) {
      state.importedPests = action.payload;
    },
    addPests(state: JobSliceState, action: { payload: Pests[] }) {
      state.pests = action.payload;
    },
    addComment(state: JobSliceState, action: { payload: string }) {
      state.comment = action.payload;
    },
    setEatenQuantity(state: JobSliceState, action: { payload: number }) {
      state.eatenQuantity = action.payload;
    },
    setReason(state: JobSliceState, action: { payload: string }) {
      state.reason = action.payload;
    },
    setEventType(state: JobSliceState, action: { payload: MonitorEventType }) {
      state.eventType = action.payload;
    },
    increment(state: JobSliceState, action: { payload: { id: string } }) {
      const pestId = action.payload.id;
      const pest = state.pests.find((item) => item.pestId === pestId);
      if (pest) {
        pest.quantity! += 1;
      }
      state.savedPests = state.pests;
    },
    incrementFor(
      state: JobSliceState,
      action: { payload: { id: string; quantity: number } }
    ) {
      const pestId = action.payload.id;
      const pest = state.pests.find((item) => item.pestId === pestId);
      if (pest) {
        pest.quantity! = action.payload.quantity;
      }
      state.savedPests = state.pests;
    },
    decrement(state: JobSliceState, action: { payload: { id: string } }) {
      const pestId = action.payload.id;
      const pest = state.pests.find((item) => item.pestId === pestId);
      if (pest && pest.quantity! > 0) {
        pest.quantity! -= 1;
      }
      state.savedPests = state.pests;
    },
    addImage(state, { payload }: PayloadAction<ImageProps>) {
      state.images.push(payload);
    },
    removeImage(state, { payload }: PayloadAction<string>) {
      state.images = state.images.filter((image) => image.uuid !== payload);
    },
    resetState(state: JobSliceState) {
      return { ...initialState };
    },
  },
});

export const creatingServiceActions = creatingServiceSlice.actions;
