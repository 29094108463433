import { ResponseType } from "axios";

import { CustomerStoreRequestPayload } from "../types/customer";

import ApiService from "./ApiService";

type CustomerResponse = ResponseType & {
  data: any;
  status: number;
};

type PropertyResponse = ResponseType & {
  data: any;
};

const CustomerService = {
  GetSevDeskCustomers: async (): Promise<CustomerResponse> => {
    return ApiService.Get("sevdesk/customers");
  },

  GetCustomer: async (customerId: string): Promise<CustomerResponse> => {
    return ApiService.Get(`customers/${customerId}`);
  },

  GetCustomerCommunicationWays: async (
    customerId: string,
    type: string
  ): Promise<CustomerResponse> => {
    return ApiService.Get(
      `sevdesk/customers/${customerId}/communication-ways`,
      {
        type,
      }
    );
  },

  GetCustomerProperties: async (
    customerId: string
  ): Promise<PropertyResponse> => {
    return ApiService.Get(`customers/${customerId}/properties`);
  },

  CreateCustomer: async (
    data: CustomerStoreRequestPayload
  ): Promise<CustomerResponse> => {
    return ApiService.Post("customers", data);
  },
};

export default CustomerService;
