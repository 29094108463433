import { useState } from "react";

import { FullScreenButton } from "../FullScreenButton/FullScreenButton";

import styles from "./full-screen-preview.module.css";

type FullScreenPreviewProps = {
  imageUrl?: string;
  children?: any;
};

const FullScreenPreview = ({ imageUrl, children }: FullScreenPreviewProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPreview = () => {
    setIsOpen(true);
  };

  const closePreview = () => {
    setIsOpen(false);
  };

  if (!isOpen) {
    return <FullScreenButton onClick={openPreview} />;
  }

  return (
    <div className={styles["fullscreen-preview"]}>
      <button
        className={styles["fullscreen-close-button"]}
        onClick={closePreview}
      >
        X
      </button>
      <div className={styles["fullscreen-content"]}>
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Preview"
            className={styles["fullscreen-image"]}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default FullScreenPreview;
