import { MouseEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useClock } from "../../hooks/useClock";
import { useScreenSize } from "../../hooks/useScreenSize";
import { RootState } from "../../store";
import { CustomerHeader } from "../Header/CustomerHeader";
import { Header } from "../Header/Header";
import { Navigation } from "../Navigation/Navigation";

import styles from "./app-layout.module.css";

type AppLayoutProps = {
  className?: string;
  wrapperClass?: "absence-wrapper" | "tasks-wrapper";
  hideHeader?: boolean;
  children: React.ReactNode;
  withBackButton?: boolean;
  onGoBack?: MouseEventHandler<HTMLImageElement>;
};

export const AppLayout = (props: AppLayoutProps) => {
  useClock();
  const navigate = useNavigate();
  const { isDesktop } = useScreenSize();
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const [headerMinimized, setHeaderMinimized] = useState(true);

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);

  // Ask to confirm before reloading the page - uncomment later
  // useEffect(() => {
  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  useEffect(() => {
    if (!isSignedIn) {
      navigate("/signin");
    }
  }, [isSignedIn, navigate]);

  const HeaderComponent = () => {
    if (isCustomerAdmin) {
      return (
        <CustomerHeader
          onClick={() => {
            if (!props.withBackButton) {
              setHeaderMinimized(false);
            }
          }}
          isMinimized={headerMinimized}
          withBackButton={props.withBackButton}
          onGoBack={props.onGoBack}
        />
      );
    } else {
      return (
        <Header
          onClick={() => {
            if (!props.withBackButton) {
              setHeaderMinimized(false);
            }
          }}
          isMinimized={headerMinimized}
          withBackButton={props.withBackButton}
          onGoBack={props.onGoBack}
        />
      );
    }
  };

  return (
    <div
      className={`${styles["app-layout"]} ${
        props.wrapperClass ? styles["app-layout"] : ""
      }`}
    >
      {!props.hideHeader && !isDesktop && <HeaderComponent />}
      <div
        className={styles["app-layout__body"]}
        style={{ overflowY: "scroll" }}
      >
        <div
          onClick={() => {
            setHeaderMinimized(true);
          }}
          className={`${styles["app-layout__content"]} ${props.className || ""}`}
        >
          {!props.hideHeader && isDesktop && <HeaderComponent />}
          {props.children}
        </div>
        <Navigation />
      </div>
    </div>
  );
};
