import { MinimalProduct, SevDeskProduct, UsedProduct } from "../types/product";

export const filterProducts = (products: MinimalProduct[]) => {
  const sevDeskProducts: SevDeskProduct[] = products
    .filter((product) => product.isSevDeskProduct && product.quantity > 0)
    .map(({ id, name, quantity }) => ({
      id: +id,
      name,
      quantity: parseFloat(quantity.toString().replace(",", ".")),
    }));

  const usedProducts: UsedProduct[] = products
    .filter((product) => !product.isSevDeskProduct && product.quantity > 0)
    .map(({ id, quantity }) => ({
      productId: id.toString(),
      usedQuantity: parseFloat(quantity.toString().replace(",", ".")),
    }));

  return { sevDeskProducts, usedProducts };
};
