import { useNavigate } from "react-router-dom";

import createTask from "./create-task.png";

export const CreateTaskIcon = () => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate("/appointments/create")}
      style={{ cursor: "pointer" }}
    >
      <img src={createTask} alt="Create task icon" />
    </div>
  );
};
