import { TrackingEventService } from "../api/TrackingEventService";
import { TimeTrackingEvent } from "../types/event";

import { getServiceStatus } from "./getServiceStatus";

export type TotalTimeResponse = {
  totalTime: number;
  firstEvent: TimeTrackingEvent | null;
  lastEvent: TimeTrackingEvent | null;
};

export const calculateTotalTime = async (
  appointmentId: string,
  employeeId?: string
): Promise<TotalTimeResponse> => {
  try {
    const { isServiceInProgress, secondsSinceLastService } =
      await getServiceStatus(appointmentId, employeeId);

    let totalSeconds = 0;
    let firstEvent = null;
    let lastEvent = null;

    if (employeeId) {
      const { data } =
        await TrackingEventService.GetTotalTimeByAppointmentPerEmployeeId(
          appointmentId,
          employeeId
        );
      totalSeconds = data.totalSeconds;
      firstEvent = data.firstEvent;
      lastEvent = data.lastEvent;
    } else {
      const { data } =
        await TrackingEventService.GetTotalTimeByAppointment(appointmentId);
      totalSeconds = data.totalSeconds;
      firstEvent = data.firstEvent;
      lastEvent = data.lastEvent;
    }

    let totalTime = totalSeconds;

    if (isServiceInProgress) {
      totalTime += secondsSinceLastService;
    }

    return { totalTime, firstEvent, lastEvent };
  } catch (error) {
    console.error(error);

    return {
      totalTime: 0,
      firstEvent: null,
      lastEvent: null,
    };
  }
};
