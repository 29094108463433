import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Deficiency } from "../types/deficiencies";
import { MinimalProduct } from "../types/product";

export type Attachment = {
  type: "Incident" | "Appointment";
  id: string;
};

export type EmailProps = {
  id: string;
  email: string;
};

export type SignatureProps = {
  url: string;
};

export type Technician = {
  firstName: string;
  lastName: string;
};

export type AppointmentSubset = {
  customerName: string;
  address: string;
  technicians: Technician[];
  typeOfAppointment: "monitoring" | "auftrag" | null;
};

export type Event = {
  type: string;
  count: number;
};

export type Monitor = {
  monitorType: string;
  events: Event[];
};

export type PropertyArea = {
  area: string;
  events: Monitor[];
};

export type PropertyAreaDeficiencies = {
  area: string;
  incidents: Deficiency[];
};

export type PropertyAreaAdjustedMonitors = {
  label: string;
  monitors: Monitor[];
};

export type DocumentationSliceState = {
  emailOwner: string;
  signature: SignatureProps;
  emails: EmailProps[];
  remark: string;
  appointmentSubset: AppointmentSubset;
  date: string;
  totalTimeWorkload: string;
  products: MinimalProduct[];
  propertyAreas: PropertyArea[];
  propertyAreasDeficiencies: PropertyAreaDeficiencies[];
  existingReceipt: boolean;
  existingReceiptId: string;
  defAppointmentId: string | undefined;
  attachedTo: Attachment;
  propertyAreaAdjustedMonitors: PropertyAreaAdjustedMonitors[];
};

const initialState: DocumentationSliceState = {
  emailOwner: "",
  signature: {
    url: "",
  },
  emails: [],
  remark: "",
  appointmentSubset: {
    customerName: "",
    address: "",
    technicians: [],
    typeOfAppointment: null,
  },
  date: "",
  totalTimeWorkload: "",
  products: [],
  propertyAreas: [],
  propertyAreasDeficiencies: [],
  existingReceipt: false,
  existingReceiptId: "",
  defAppointmentId: undefined,
  attachedTo: {
    type: "Appointment",
    id: "",
  },
  propertyAreaAdjustedMonitors: [],
};

export const creatingDocumentationSlice = createSlice({
  name: "documentation",
  initialState: initialState,
  reducers: {
    setEmailOwner(state: DocumentationSliceState, action: { payload: string }) {
      state.emailOwner = action.payload;
    },
    setSignatureImage(state, action: PayloadAction<SignatureProps>) {
      state.signature = action.payload;
    },
    setRemark(state, action: PayloadAction<string>) {
      state.remark = action.payload;
    },
    setAppointmentSubset(state, action: PayloadAction<AppointmentSubset>) {
      state.appointmentSubset = action.payload;
    },
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setTotalTimeWorkload(state, action: PayloadAction<string>) {
      state.totalTimeWorkload = action.payload;
    },
    addEmail(state, action: PayloadAction<EmailProps>) {
      const emailExists = state.emails.some(
        (email) => email.email === action.payload.email
      );

      if (!emailExists) {
        state.emails.push(action.payload);
      }
    },
    setProducts(state, action: PayloadAction<MinimalProduct[]>) {
      state.products = action.payload;
    },
    setPropertyAreaEvents(state, action: PayloadAction<PropertyArea[]>) {
      state.propertyAreas = action.payload;
    },
    setPropertyAreaDeficiencies(
      state,
      action: PayloadAction<PropertyAreaDeficiencies[]>
    ) {
      state.propertyAreasDeficiencies = action.payload;
    },
    setPropertyAreaAdjustedMonitors(
      state,
      action: PayloadAction<PropertyAreaAdjustedMonitors[]>
    ) {
      state.propertyAreaAdjustedMonitors = action.payload;
    },
    setExistingReceipt(state, action: PayloadAction<boolean>) {
      state.existingReceipt = action.payload;
    },
    setExistingReceiptId(state, action: PayloadAction<string>) {
      state.existingReceiptId = action.payload;
    },
    setDefAppointment(state, action: PayloadAction<string>) {
      state.defAppointmentId = action.payload;
    },
    removeEmail(state, action: PayloadAction<string>) {
      state.emails = state.emails.filter(
        (email) => email.id !== action.payload
      );
    },
    setAttachedTo(state, action: PayloadAction<Attachment>) {
      state.attachedTo = action.payload;
    },
    resetState(state: DocumentationSliceState) {
      return { ...initialState };
    },
  },
});

export const creatingDocumentationActions = creatingDocumentationSlice.actions;
