import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import MonitorService from "../../api/MonitorService";
import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomCollapse } from "../../components/CustomCollapse/CustomCollapse";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { CustomToggleSelect } from "../../components/CustomToggleSelect/CustomToggleSelect";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import FullScreenPreview from "../../components/FullScreenPreview/FullScreenPreview";
import { Info } from "../../components/Info/Info";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { MultiImageUploader2 } from "../../components/MultiImageUploader2/MultiImageUploader2";
import { NoData } from "../../components/NoData/NoData";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { MonitorIcon } from "../../icons/MonitorIcon/MonitorIcon";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { go } from "../../services/NavigationService";
import { transformToThreeDigit } from "../../services/TypographyService";
import { RootState } from "../../store";
import { creatingMonitorActions } from "../../store/monitor-slice";
import { productSliceActions } from "../../store/products-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import styles from "../../styles/create-monitor.module.css";
import { Document } from "../../types/document";
import { ImageProps } from "../../types/image";
import { Crop } from "../../types/map";
import { Monitor } from "../../types/monitor";
import { PropertyArea } from "../../types/property";
import { filterProducts } from "../../utils/filterProducts";
import { uploadMap } from "../../utils/uploadMap";

export const CreateMonitor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const monitorPosition = useSelector(
    (state: RootState) => state.monitor.monitorPosition
  );
  const monitor = useSelector((state: RootState) => state.monitor);
  const products = useSelector((state: RootState) => state.products);
  const appointment = useSelector((state: RootState) => state.appointment);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [map, setMap] = useState<Document | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [failedToFetchData, setFailedToFetchData] = useState(false);
  const [loading, setLoading] = useState(false);

  const propertyArea = location.state?.propertyArea;
  const monitorTypeName = location.state?.monitorTypeName;
  const monitorId = location.state?.monitorId;

  const propertyAreaMonitorsCount = useSelector((state: RootState) => {
    const property = state.appState.properties.find(
      (property) => property.id === appointment.propertyId
    );
    if (!property) return 0; // Property not found, return 0

    const area = property.propertyAreas.find(
      (area: PropertyArea) => area.id === propertyArea.id
    );
    if (!area || !area.includes || !area.includes.monitors) return 0; // Property area or monitors not found, return 0

    const monitorCount = area.includes.monitors.filter(
      (monitor: Monitor) => monitor.monitorTypeId === monitorId
    ).length;

    return monitorCount;
  });

  const assignedMonitorNumber = transformToThreeDigit(
    propertyAreaMonitorsCount + 1
  );

  useEffect(() => {
    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));
    setLoading(true);
    PropertyService.GetPropertyArea(propertyArea.id)
      .then((propertyArea: any) => {
        setMap(propertyArea.data.map);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [
    appointment,
    dispatch,
    location.pathname,
    monitorId,
    monitorTypeName,
    propertyArea,
  ]);

  // Check if monitor.location is not empty
  const isLocationEntered = useCallback(() => {
    return !!monitor.location.trim();
  }, [monitor.location]);

  const [isCreateMonitorButtonDisabled, setIsCreateMonitorButtonDisabled] =
    useState(!isLocationEntered());

  const handleCreateMonitorEvent = async (monitorCreate: any) => {
    const { sevDeskProducts, usedProducts } = filterProducts(products);

    await MonitorService.CreateMonitorEvent({
      monitorId: monitorCreate.data.id,
      eventType: "initialSetup",
      jobId: appointment.jobs?.slice(-1).pop().id,
      sevdesk_products: sevDeskProducts,
      usedProducts: usedProducts,
      propertyAreaInfo: {
        ...monitor.monitorPosition,
        propertyAreaId: propertyArea.id,
      },
    })
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMonitorErstellenClicked = async () => {
    setRequestLoading(true);
    await MonitorService.AddMonitor({
      monitorTypeId: monitorId,
      propertyAreaId: propertyArea.id,
      location: monitor.location,
      mapPosition: {
        mapVersionId: null,
        mapPoint: {
          ...monitor.monitorPosition,
        },
      },
      isTemporary: monitor.isTemporary,
    })
      .then((monitor) => {
        return handleCreateMonitorEvent(monitor);
      })
      .then(() => {
        setFailedToFetchData(false);
        dispatch(creatingMonitorActions.resetState());
        dispatch(productSliceActions.resetState());
        dispatch(creatingMonitorActions.setMonitorPosition({ x: 1, y: 1 })); //reset values
        setRequestLoading(false);
        navigate(`/properties/${appointment.id}/show`);
      })
      .catch((error) => {
        console.log(error);
        setRequestLoading(false);
        setFailedToFetchData(true);
      });
  };

  const handleLocationChange = (location: string) => {
    dispatch(creatingMonitorActions.setLocation({ location }));
  };

  const handleMapUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = { id: propertyArea.id };
    uploadMap(event, data, setMap);
  };

  const handleTextareaChange = (note: string) => {
    dispatch(creatingMonitorActions.setComment({ comment: note }));
  };

  const handleMaterialsClicked = () => {
    navigate(`/products`);
  };

  const handleAbortMonitorCreation = () => {
    setIsModalOpen(false);

    dispatch(activeTabsActions.setDashbaordUrl(""));
    dispatch(creatingMonitorActions.resetState());
    dispatch(productSliceActions.resetState());

    navigate(`/properties/${appointment.id}/show`);
  };

  const handleBackButtonClicked = () => {
    dispatch(creatingMonitorActions.setMonitorPosition({ x: 1, y: 1 })); //reset values
    dispatch(productSliceActions.resetState());
    dispatch(creatingMonitorActions.resetState());
    go(navigate, "/monitors/create/step-2", location.state);
  };

  const handleImageUploaded = (image: ImageProps) => {
    dispatch(creatingMonitorActions.addImage(image));
  };

  const handleImageDelete = (uuid: string) => {
    dispatch(creatingMonitorActions.removeImage(uuid));
  };
  const handleCrop = ({ x, y }: Crop) => {
    dispatch(creatingMonitorActions.setMonitorPosition({ x, y }));
  };
  const handleLoadingChange = (loading: boolean) => {
    setIsCreateMonitorButtonDisabled(loading);
  };
  const monitorPinAbbr = useMemo(
    () => ({
      name: monitorTypeName?.slice(0, 3).toUpperCase(),
      number: assignedMonitorNumber,
    }),
    [monitorTypeName, assignedMonitorNumber]
  );

  useEffect(() => {
    setIsCreateMonitorButtonDisabled(!isLocationEntered());
  }, [isLocationEntered, monitor.location]);

  return (
    <AppLayout hideHeader>
      <div>
        <BackButtonHeader handleBackButtonClicked={handleBackButtonClicked} />
        <div className={styles["content-wrapper"]}>
          <AppointmentDetails />

          <div className={styles["content"]}>
            <SubMenu showSearchButton={false}>
              <span>{`${monitorTypeName} ${assignedMonitorNumber}, ${propertyArea?.name}`}</span>
              <MonitorIcon color="dark" />
            </SubMenu>

            {loading && <LoadingSpinner loading={loading} size={60} top="0" />}

            {map && (
              <div className={styles["image"]}>
                <PinCropMap
                  map={map}
                  crop={monitorPosition}
                  onCropChange={handleCrop}
                  monitorPinAbbr={monitorPinAbbr}
                  isDraggable={false}
                  zoom={false}
                />
                <FullScreenPreview>
                  <PinCropMap
                    map={map}
                    crop={monitorPosition}
                    onCropChange={handleCrop}
                    monitorPinAbbr={monitorPinAbbr}
                  />
                </FullScreenPreview>
              </div>
            )}

            {!map && !loading && (
              <>
                <NoData onClick={handleMapUpload}>Karte Hinzufügen</NoData>

                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
              </>
            )}

            <CustomInput
              label="Standortinformation"
              placeholder="Kein Standort eingetragen"
              onInputChange={handleLocationChange}
              onBlur={() => {}}
              initialValue={monitor.location}
              maxLength={60}
            />

            <CustomToggleSelect
              selected={monitor.isTemporary}
              backgroundColor="white"
              onClick={() => dispatch(creatingMonitorActions.setIsTemporary())}
            >
              Temporärer Monitor?
            </CustomToggleSelect>

            <CustomCollapse
              onClick={handleMaterialsClicked}
              products={products}
            >
              Arbeits-, Material- und Präparateeinsatz
            </CustomCollapse>

            <MultiImageUploader2
              images={monitor.images}
              onImageUpload={handleImageUploaded}
              onDeleteImage={handleImageDelete}
              onLoadingChange={handleLoadingChange}
            />

            <CustomTextArea
              placeholder="Zusätzlicher Text"
              onTextareaChange={handleTextareaChange}
              defaultValue={monitor.comment}
            />
          </div>
        </div>

        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            message={t("AbortMonitor")}
            onCancel={() => setIsModalOpen(false)}
            onConfirm={handleAbortMonitorCreation}
          />
        )}

        <FloatingButtons>
          <CustomButton
            color="gray"
            icon="monitor"
            iconPosition="right"
            loading={requestLoading}
            onClick={handleMonitorErstellenClicked}
            disabled={isCreateMonitorButtonDisabled}
            width={70}
          >
            Monitor Erstellen
          </CustomButton>

          <CustomButton
            color="red"
            width={20}
            onClick={() => setIsModalOpen(true)}
          >
            X
          </CustomButton>
        </FloatingButtons>
        {!requestLoading && failedToFetchData && (
          <Info type="error">{t("Data retrieval failed")}</Info>
        )}
      </div>
    </AppLayout>
  );
};
