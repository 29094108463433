import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackEndService from "../../api/BackEndService";
import CustomerService from "../../api/CustomerService";
import PropertyService from "../../api/PropertyService";
import { generateUUIDFromID } from "../../services/UUIDGeneratorService";
import { RootState } from "../../store";
import { searchActions } from "../../store/search-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { creatingTaskActions } from "../../store/task-slice";
import { SevDeskCustomer } from "../../types/customer";
import { List } from "../List/List";
import PullToRefresh from "../PullToRefresh/PullToRefresh";

import styles from "./task-form.module.css";

export const StepOne = (): JSX.Element => {
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState([]);
  const [initialCustomers, setInitialCustomers] = useState([]);
  const [isNavigating, setIsNavigating] = useState(false);
  const searchValue = useSelector((state: RootState) => state.search.value);

  // Fetch fresh list of sevdesk customers
  useEffect(() => {
    fetchCustomers();
  }, []);

  // Fetch sevdesk customers list
  const fetchCustomers = async () => {
    try {
      const customers = await CustomerService.GetSevDeskCustomers();

      const sortedCustomers = customers.data.sort((a: any, b: any) => {
        const nameA = (a.name || a.surename || "").toLowerCase();
        const nameB = (b.name || a.surename || "").toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setCustomers(sortedCustomers);
      setInitialCustomers(sortedCustomers);
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch customer properties by customer id
  const fetchCustomerProperties = async (customerId: string) => {
    const _customerId = generateUUIDFromID(customerId?.toString()!);

    try {
      const sevdeskProperties = await BackEndService.GetObjects(customerId); //sevdesk objects
      const properties = await PropertyService.GetProperties({
        "filter[customerId]": _customerId,
      }); //backend objects

      // Set customer properties that we need to list on step two
      dispatch(
        creatingTaskActions.setCustomerProperties({
          customerProperties: [...sevdeskProperties.data, ...properties.data],
        })
      );

      dispatch(activeTabsActions.setAppointmentsStep(2));
    } catch (error) {
      console.log(error);
    }
  };

  const onSelectCustomer = (customer: Customer) => {
    setIsNavigating(true);
    dispatch(creatingTaskActions.setCustomer({ customer: customer }));
    dispatch(
      creatingTaskActions.setAdditionalDataCustomer({
        customer: {
          id: customer.id,
          name: customer.name,
        },
      })
    );

    fetchCustomerProperties(customer.id);

    dispatch(searchActions.setIsExpanded(false));
    dispatch(searchActions.setSearchValue(""));
  };

  const handlePullToRefresh = (callback: Function) => {
    fetchCustomers()
      .then((customers) => {
        callback();
      })
      .catch((error) => {
        callback(error);
      });
  };

  useEffect(() => {
    if (!isNavigating) {
      if (searchValue.length >= 3) {
        const lowerCaseSearchValue = searchValue.toLowerCase();

        setCustomers(
          initialCustomers.filter(
            (customer: SevDeskCustomer) =>
              customer.name?.toLowerCase().includes(lowerCaseSearchValue) ||
              customer.surename?.toLowerCase().includes(lowerCaseSearchValue) ||
              customer.familyname
                ?.toLowerCase()
                .includes(lowerCaseSearchValue) ||
              (customer.surename + " " + customer.familyname)
                .toLowerCase()
                .includes(lowerCaseSearchValue)
          )
        );
      } else if (searchValue.length === 0) {
        setCustomers(initialCustomers);
      }
    }
  }, [searchValue, initialCustomers, isNavigating]);

  return (
    <PullToRefresh
      className={styles["customer-refresh"]}
      onRefresh={handlePullToRefresh}
    >
      <List
        subtitle={t("CustomerSelect")}
        data={customers.map((customer: SevDeskCustomer) => ({
          id: customer.id.toString(),
          name: customer.name || customer.surename + " " + customer.familyname,
        }))}
        onItemSelected={(customer: any) => {
          onSelectCustomer(customer);
        }}
      />
    </PullToRefresh>
  );
};
