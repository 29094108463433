import classNames from "classnames";
import { t } from "i18next";
import { useSelector } from "react-redux";

import { useClockNavigator } from "../../hooks/useClockNavigator";
import { splitDateTime } from "../../services/DateTimeService";
import { RootState } from "../../store";
import { checkIsRunning } from "../../store/clock-slice";
import Clock from "../Timer/Clock";

import styles from "./appointment-details.module.css";

type AppointmentDetailsProps = {
  isServicesOverview?: boolean;
  isServicesCompletion?: boolean;
  isDeficiencyOverview?: boolean;
  showMangelTag?: boolean;
  handleClockClickReset?: () => void;
};

export const AppointmentDetails = ({
  isServicesOverview = false,
  isServicesCompletion = false,
  isDeficiencyOverview = false,
  showMangelTag = false,
  handleClockClickReset = () => {},
}: AppointmentDetailsProps): JSX.Element => {
  const { handleClockClick } = useClockNavigator();
  const user = useSelector((state: RootState) => state.user.currentUser.user);
  const appointment = useSelector((state: RootState) => state.appointment);
  const isRunning = useSelector((state: RootState) =>
    checkIsRunning(state, appointment.id, user!.id)
  );
  const task = useSelector((state: RootState) => state.task);
  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const isTaskValid = task && task.customer !== "";
  const object = isTaskValid ? task : appointment;

  const time = isTaskValid
    ? task.time
    : splitDateTime(appointment?.plannedStart ?? "").time || null;
  const isDeficiency = appointment.incidents.length > 0 || showMangelTag;
  const typeOfAppointment =
    object?.typeOfAppointment === "auftrag"
      ? "Bekämpfung"
      : object?.typeOfAppointment;

  const classes = classNames(styles["task-details__card"], {
    [styles["task-details__card--green"]]: isRunning,
    [styles["task-details__card--white"]]: !isRunning,
  });

  const onClickClock = () => {
    handleClockClickReset();
    handleClockClick();
  };

  return (
    <div className={classes}>
      {isRunning ? <Clock pulledUp onClick={onClickClock} /> : null}

      {isServicesOverview && (
        <div className={styles["task-details__service-summary"]}>
          <span>{t("ServiceSummary")}</span>
        </div>
      )}

      {isDeficiencyOverview && (
        <div className={styles["task-details__service-summary"]}>
          <span>{t("DeficiencySummary")}</span>
        </div>
      )}

      {isServicesCompletion && (
        <div className={styles["task-details__service-summary"]}>
          <span>{t("Completion of service")}</span>
        </div>
      )}

      <button className={styles["task-details__circle"]}>
        {isCustomerAdmin ? null : time}
      </button>

      <div className={styles["task-details__task"]}>
        <h3 className={styles["task-details__titles"]}>
          {object?.additionalData?.customer.name}
        </h3>

        <h4 className={styles["task-details__infos"]}>
          {object?.additionalData?.property.address}
        </h4>

        {!isCustomerAdmin && !isViewOnly && (
          <button
            className={`${styles["task-details__button"]} ${
              isDeficiency && styles["task-details__button--orange"]
            }`}
          >
            {isDeficiency ? t("Deficiency") : typeOfAppointment}
          </button>
        )}
      </div>
    </div>
  );
};
