import { useEffect, useState } from "react";

import styles from "../layout/AppLayout/app-layout.module.css";

export const useInfiniteScroll = (element: HTMLElement, hasMore: boolean) => {
  const [isFetching, setIsFetching] = useState(false);
  const isMobile = window.innerWidth <= 600;
  const buffer = isMobile ? 50 : 150;

  useEffect(() => {
    const layout = document.getElementsByClassName(
      styles["app-layout__content"]
    )[0] as HTMLElement;
    if (!layout) return;

    let debounceTimeout: any;

    const handleScroll = () => {
      clearTimeout(debounceTimeout);

      debounceTimeout = setTimeout(() => {
        const hasReachedBottom =
          layout.scrollHeight - layout.scrollTop <=
          layout.clientHeight + buffer;

        if (hasReachedBottom && !isFetching && hasMore) {
          setIsFetching(true);
        }
      }, 100);
    };

    layout.addEventListener("scroll", handleScroll);

    return () => {
      layout.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeout);
    };
  }, [isFetching, hasMore, element]);

  return [isFetching, setIsFetching] as const;
};
