const {
  REACT_APP_API_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_SERVICES_ID,
  REACT_APP_CUSTOMERS_ID,
  REACT_APP_COMPANY_NAME,
  REACT_APP_COMPANY_ADDRESS,
  REACT_APP_COMPANY_CITY,
} = process.env;
export class ApiConfig {
  apiUrl: string;
  googleMapsKey: string;
  servicesId: number;
  customersId: number;
  companyName: string;
  companyAddress: string;
  companyCity: string;

  constructor() {
    this.apiUrl = REACT_APP_API_URL!;
    this.googleMapsKey = REACT_APP_GOOGLE_MAPS_API_KEY!;
    this.servicesId = parseInt(REACT_APP_SERVICES_ID!);
    this.customersId = parseInt(REACT_APP_CUSTOMERS_ID!);
    this.companyName = REACT_APP_COMPANY_NAME!;
    this.companyAddress = REACT_APP_COMPANY_ADDRESS!;
    this.companyCity = REACT_APP_COMPANY_CITY!;
  }
}
const config = new ApiConfig();
export default config;
