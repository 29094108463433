import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppointmentService from "../../api/AppointmentService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import Modal from "../../components/Modal/Modal";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { oldMonitorsActions } from "../../store/old-monitors-slice";
import styles from "../../styles/monitor-adjust-position.module.css";

export const MonitorRemoval = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const monitor = useSelector((state: RootState) => state.monitor.monitor);
  const propertyAreaId = useSelector(
    (state: RootState) => state.monitor.monitorTypes.propertyAreaId
  );
  const monitorType = useSelector(
    (state: RootState) => state.monitor.monitorTypes.monitorType
  );
  const appointment = useSelector((state: RootState) => state.appointment);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [textArea, setTextArea] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const saveChanges = async () => {
    const currentTime = new Date().toISOString();

    dispatch(
      oldMonitorsActions.setOldMonitors({
        id: monitor.id,
        position: monitor.position.toString(),
        location: monitor.location,
        appointmentId: appointment.id,
        createdAt: currentTime,
        action: "removed",
        propertyAreaId: propertyAreaId,
        monitorType: monitorType,
      })
    );

    setLoading(true);

    await AppointmentService.UpdateMonitorDetails(appointment.id, {
      monitor_id: monitor.id,
      reason: textArea,
      is_active: false,
    })
      .then(() => {
        navigate(`/properties/${appointment.id}/show`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTextareaChange = (note: string) => {
    setTextArea(note);
  };

  return (
    <AppLayout hideHeader>
      <Modal
        isOpen={isModalOpen}
        iconType="remove"
        message={t("AreYouSureModal")}
        note={"RemoveMonitorNote"}
        cancelText={"Cancel"}
        confirmText={"RemoveMonitor"}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={saveChanges}
        closeButtonColor="#3D5170"
        confirmButtonColor="#cb3844"
      />

      <BackButtonHeader handleBackButtonClicked={handleBack} />
      <AppointmentDetails />

      <div className={styles["content-wrapper"]}>
        <div className={styles["content"]}>
          <span className={styles["monitor-adjust--title"]}>
            {t("RemoveMonitor")}
          </span>

          <CustomTextArea
            placeholder={t("MovingMonitorReason")}
            onTextareaChange={handleTextareaChange}
            defaultValue={textArea}
          />
        </div>
        <FloatingButtons>
          <CustomButton
            color="gray"
            width={80}
            onClick={showModal}
            loading={loading}
          >
            {t("Continue")}
          </CustomButton>
        </FloatingButtons>
      </div>
    </AppLayout>
  );
};
