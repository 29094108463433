import { MouseEventHandler } from "react";

import leftArrowDark from "./left-arrow-dark.png";
import leftArrow from "./left-arrow.png";

export type LeftArrowIconProps = {
  color?: "dark" | "light";
  handleBackButtonClicked?: MouseEventHandler<HTMLImageElement>;
};

const LEFT_ARROW_IMAGES: Record<"dark" | "light", string> = {
  dark: leftArrowDark,
  light: leftArrow,
};

export const LeftArrowIcon = ({
  color,
  handleBackButtonClicked,
}: LeftArrowIconProps) => (
  <img
    src={LEFT_ARROW_IMAGES[color || "dark"]}
    alt="Left arrow icon"
    style={{ height: "16px" }}
    onClick={handleBackButtonClicked}
  />
);
