import { useDispatch } from "react-redux";

import { activeTabsActions } from "../store/tabs-slice";
import { Deficiency } from "../types/deficiencies";

import { useRedirect } from "./useRedirect";

export const useDeficiencyRedirect = () => {
  const dispatch = useDispatch();
  const { setDashboardUrlAndRedirect } = useRedirect();

  const handleRedirect = (deficiency: Deficiency, tab = "ResolvedReports") => {
    if (deficiency) {
      const url = `/deficiencies/${deficiency.id}/overview/readonly`;
      setDashboardUrlAndRedirect(url);
      dispatch(activeTabsActions.setPreviousPath("/deficiencies"));
    } else {
      setDashboardUrlAndRedirect(`/deficiencies?tab=${tab}`);
    }
  };

  return {
    handleRedirect,
  };
};
